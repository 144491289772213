import { render } from "@testing-library/react";
import React, { Component, useContext, useState } from "react";
import { BsFillHeartFill, BsHeart, BsCart2, BsBag } from "react-icons/bs";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Carousel } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import IncDecCounter from "./IncDecCounterControl";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  Form,
  Stack,
  Button,
  Modal,
  ListGroup,
  Badge,
  FloatingLabel,
  Row,
  Col,
  Table,
  InputGroup,
  Container as div,
} from "react-bootstrap";

import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import CartContext from "./CartContext";

export class ProductSingleProductView1 extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      currentImageFileName: "",
      currentPosterName: "",
      qty: 1,
      prod: "",

      carouselIndex: 0,
      description2Html: "",
      reload: true,
      activeButton: "",
    };
  }

  OnImageChange = (e, i) => {
    if (
      this.state.currentImageFileName ==
        this.state.prod.productImages[i].fileName &&
      this.state.currentPosterName ==
        this.state.prod.productImages[i].posterName
    )
      return;

    this.setState(
      {
        currentImageFileName: this.state.prod.productImages[i].fileName,
      },
      () => {
        this.setState(
          {
            currentPosterName: this.state.prod.productImages[i].posterName,
          },
          () =>
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            })
        );
      }
    );
    this.setState({
      activeButton: this.state.prod.productImages[i].fileName,
    });
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    this.context.addToMyCart({
      prod: this.context.shopnowProduct,
      qty: this.state.qty,
    });
    // this.context.forceUpdateCartView();
    this.props.onHide();
  };
  AddToCart = () => {
    this.context.addToMyCart({
      prod: this.context.shopnowProduct,
      qty: this.state.qty,
    });
  };

  handleQtyChange = (event) => {
    if (event.target.value > 0) this.setState({ qty: event.target.value });
  };

  GetProductById = (productId) => {
    var res = axios
      .get(process.env.REACT_APP_API + "Consumers/GetProductById/" + productId)
      .then((response) => {
        if (response.status === 200) {
          response.data.mrp = this.context.prodsAll.filter(
            (f) => f.id == response.data.id
          )[0].mrp;
          this.setState({ prod: response.data });
          this.setState({
            currentImageFileName:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .fileName
                : "",
          });
          this.setState({
            currentPosterName:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .posterName
                : "",
          });
          this.setState({
            activeButton:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .fileName
                : "",
          });
        }
      })
      .catch((error) => {
        console.error("GetProductById - There was an error! ", error);
      });
  };
  GetProductDescription2 = (productId) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetProductDescription2Html/" +
          productId
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ description2Html: response.data });
        }
      })
      .catch((error) => {
        console.error("GetProductDescription2 - There was an error! ", error);
      });
  };

  GetMainCaegoryImageFileName = () => {
    if (this.context.mainCategories.length > 0) {
      var maincategory = this.context.mainCategories.filter(
        (f) => f.id == this.context.shopnowProduct.mainCategoryId
      )[0];
      if (maincategory && maincategory.name == "GREEN SunShine")
        return "dist/grnimages/products/sunshine-logo.png";
      if (maincategory && maincategory.name == "GREEN Em'Pawa")
        return "dist/grnimages/products/empower-logo.png";
      if (maincategory && maincategory.name == "GREEN SunSmart")
        return "dist/grnimages/products/sun-smart-logo.png";
    }
  };

  handleCarouselSelect = (selectedIndex, e) => {
    this.setState({ carouselIndex: selectedIndex });
  };
  IsVideo(filename) {
    if (filename) {
      var f = filename.split(".").pop();
      if (f == "mp4") return true;
    }
    return false;
  }
  GetSubCategoryName = (id) => {
    if (id && this.context.subCategories.length > 0) {
      return this.context.subCategories.filter((m) => m.id == id)[0].name;
    }
    return "";
  };

  onMouseLeaveQty = (e) => {
    alert("hi");
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.context.shopnowProduct != "") {
      this.GetProductById(this.context.shopnowProduct.id);
      this.GetProductDescription2(this.context.shopnowProduct.id);
    }
    this.context.SetHomePageLoadedStatus();
  }

  componentDidUpdate() {
    if (this.context.refreshSinglePageView == true) {
      this.context.SetRefreshSinglePageView(false);
      this.GetProductById(this.context.shopnowProduct.id);
    }

    if (this.context.refreshIncDecControl == true) {
      this.context.SetRefreshIncDecControl(false);
      this.setState({ reload: false }, () => {
        this.setState({ reload: true });
      });
    }
    if (
      this.context.reloadProductListView === "true" &&
      this.state.prod != "" &&
      this.context.prodsAll.length > 0
    ) {
      this.state.prod.mrp = this.context.prodsAll.filter(
        (f) => f.id == this.state.prod.id
      )[0].mrp;

      this.setState(
        {
          prod: this.state.prod,
        },
        () => {}
      );
      this.context.UpdateReloadProductListView("false");
      this.setState({ reload: false }, () => {
        this.setState({ reload: true });
      });
    }
  }

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;

    return (
      <>
        <Helmet>
          {/* <link rel="icon" type="image/x-icon" href="dist/images/fav.ico" /> */}
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet>
        <Helmet>
          <title>{this.state.prod ? this.state.prod.name : ""}</title>
          {/* <meta content="Online Store" name="keywords" /> */}
          <meta
            content={this.state.prod ? this.state.prod.description : ""}
            name="description"
          />
        </Helmet>
        {this.state.reload == true ? (
          <>
            {window.innerWidth <= 1100 ? (
              <>
                <section class="bg-white  ">
                  <div
                    class="container text-black"
                    style={{ paddingTop: "85px" }}
                  >
                    <Row>
                      <Col>
                        <div class="text">
                          {window.location.hostname == "shop.green.com.pg" ? (
                            <>
                              <h2 class="bold_font">
                                {this.GetSubCategoryName(
                                  this.context.shopnowProduct.subCategoryId
                                )}
                                <br />
                              </h2>
                            </>
                          ) : (
                            <></>
                          )}
                          <Stack direction="horizontal">
                            <h4 class="semibold_font pt-2">
                              {this.context.shopnowProduct
                                ? this.context.shopnowProduct.name
                                : ""}
                            </h4>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </Stack>
                        </div>

                        {this.context.theme.allProductToGetQuote == true ? (
                          <>
                            <div className="my-3">
                              <Nav.Link
                                as={Link}
                                className="align-content-center border-0 buttonwithshadow w-50"
                                style={{
                                  backgroundColor: "#00ff00",
                                  color: "white",
                                  fontSize: "18px",
                                  fontFamily: "Helvetica",
                                }}
                                to="/QuotePage"
                                onClick={(e) => {
                                  this.context.AddToWishList(
                                    this.context.shopnowProduct
                                  );
                                }}
                              >
                                Get Quote ...
                              </Nav.Link>
                            </div>
                          </>
                        ) : (
                          <>
                            {this.state.prod.discount > 0 ? (
                              <>
                                <div class="row pt-3 br-btm">
                                  <div class="col-auto">
                                    <h2>
                                      <span class="semibold_font">
                                        {Math.round(
                                          Number(
                                            this.state.prod.mrp -
                                              (this.state.prod.mrp *
                                                this.state.prod.discount) /
                                                100
                                          ),
                                          0
                                        ).toLocaleString(
                                          this.context.storeSettings
                                            .defaultLocale,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency:
                                              this.context.storeSettings
                                                .defaultCurrency,
                                          }
                                        )}
                                      </span>
                                    </h2>
                                    <p class="bold cl-grey">
                                      Inclusive of all taxes
                                    </p>
                                  </div>
                                  <div class="col">
                                    <p class="mb-0">
                                      MRP{" "}
                                      <del>
                                        {Math.round(
                                          this.state.prod.mrp,
                                          0
                                        ).toLocaleString(
                                          this.context.storeSettings
                                            .defaultLocale,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency:
                                              this.context.storeSettings
                                                .defaultCurrency,
                                          }
                                        )}
                                      </del>
                                    </p>
                                    <h5 class="bold cl-gra-green">
                                      ({this.state.prod.discount}% OFF)
                                    </h5>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div class="row pt-3 br-btm">
                                  <div class="col-auto">
                                    <h2>
                                      <span class="semibold_font">
                                        {Math.round(
                                          Number(
                                            this.state.prod.mrp -
                                              (this.state.prod.mrp *
                                                this.state.prod.discount) /
                                                100
                                          ),
                                          0
                                        ).toLocaleString(
                                          this.context.storeSettings
                                            .defaultLocale,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency:
                                              this.context.storeSettings
                                                .defaultCurrency,
                                          }
                                        )}
                                      </span>
                                    </h2>
                                    <p class="bold cl-grey">
                                      Inclusive of all taxes
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="my-3">
                              <div class="row">
                                {this.context.myCart.filter(
                                  (f) => f.prod.id == this.state.prod.id
                                ).length == 1 ? (
                                  <>
                                    <Stack direction="horizontal">
                                      <div>
                                        <h6 for="" class="col-form-label bold">
                                          Quantity : &nbsp;&nbsp;
                                        </h6>
                                      </div>
                                      <div className="w-50 cartTable">
                                        <IncDecCounter
                                          intvalue={
                                            this.context.myCart.filter(
                                              (f) =>
                                                f.prod.id == this.state.prod.id
                                            )[0].qty
                                          }
                                          valueChangeCallback={(e) => {
                                            if (e === "") {
                                              this.setState({ qty: 0 });
                                            } else if (e === 0) {
                                              this.setState({ qty: 1 });
                                              this.context.deleteItemOnMyCart(
                                                this.state.prod
                                              );
                                              this.context.forceUpdateCartView();
                                            } else {
                                              this.context.UpdateQtyToCart(
                                                this.state.prod,
                                                e
                                              );
                                              this.context.forceUpdateCartView();
                                            }
                                          }}
                                        ></IncDecCounter>
                                      </div>
                                    </Stack>
                                  </>
                                ) : (
                                  <>
                                    <div class="row  align-items-center">
                                      <div class="col-auto">
                                        <h6 for="" class="col-form-label bold">
                                          Quantity :{" "}
                                        </h6>
                                      </div>
                                      <div
                                        class="col-auto"
                                        autoComplete="new-off"
                                      >
                                        <Form.Control
                                          type="number"
                                          // min={1}
                                          required
                                          autoComplete="new-off"
                                          role="presentation"
                                          className="form-control product_quantity"
                                          value={this.state.qty}
                                          style={{
                                            fontSize: "16px",
                                          }}
                                          onChange={this.handleQtyChange}
                                        />
                                      </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div class="col-auto">
                                      {window.location.hostname ==
                                      "shop.green.com.pg" ? (
                                        <>
                                          <Button
                                            className="btn-green d-inline-block"
                                            disabled={
                                              this.state.qty <= 0 ||
                                              this.state.qty == ""
                                            }
                                            onClick={this.AddToCart}
                                          >
                                            ADD TO CART
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            style={{
                                              backgroundColor:
                                                this.context.theme
                                                  .mainNavBarBackgroundColor,
                                              color:
                                                this.context.theme
                                                  .mainNavBarTextColor,
                                            }}
                                            onClick={this.AddToCart}
                                            disabled={
                                              this.state.qty <= 0 ||
                                              this.state.qty == ""
                                            }
                                          >
                                            ADD TO CART
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{}}>
                          <Form.Group
                            controlId="Image"
                            className="text-center "
                          >
                            <div class="item  m-0 py-0 imgcenter">
                              {/* <div className="img-hover-zoom m-0 p-0 w-100"> */}
                              {this.state.reload == true ? (
                                <>
                                  <div className=" m-0 p-0 w-100 border">
                                    {this.IsVideo(
                                      this.state.currentImageFileName
                                    ) == true ? (
                                      <>
                                        <video
                                          preload="none"
                                          width="100%"
                                          height="320px"
                                          controls
                                          poster={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            this.state.currentPosterName +
                                            this.context.store.storageSasToken
                                          }
                                        >
                                          <source
                                            src={
                                              this.context.store
                                                .storageBlobUrl +
                                              this.context.store
                                                .storageBlobContainerName +
                                              "/images/" +
                                              this.state.currentImageFileName +
                                              this.context.store.storageSasToken
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </>
                                    ) : (
                                      <>
                                        <Zoom>
                                          <img
                                            className=" border-0 text-center align-middle imgcenter"
                                            style={{
                                              width: "100%",
                                              height: "10cm",
                                              // this.context.theme
                                              //   .productSingleViewProductCardImageHeight +
                                              // "cm",
                                            }}
                                            src={
                                              this.context.store
                                                .storageBlobUrl +
                                              this.context.store
                                                .storageBlobContainerName +
                                              "/images/" +
                                              this.state.currentImageFileName +
                                              this.context.store.storageSasToken
                                            }
                                          />
                                        </Zoom>
                                      </>
                                    )}
                                    {this.state.prod.shortAd ? (
                                      <span className="ribbonleftgreen ribboncorners">
                                        {this.state.prod.shortAd}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    {this.state.prod.discount > 0 ? (
                                      <>
                                        {/* <span class="notify-badge-left"> */}
                                        <span
                                          // class="notify-badge-left"
                                          className="ribbonmodernright "
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Discount"
                                          style={{
                                            backgroundColor: "#23B14D",
                                            color: "white",
                                          }}
                                        >
                                          {this.state.prod.discount}% off
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <span
                                class="notify-badge-right_share"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share me"
                                style={{
                                  cursor: "pointer",
                                  // backgroundColor: "black",
                                }}
                              >
                                <RWebShare
                                  data={{
                                    text: this.state.prod.name,
                                    url:
                                      window.location.origin +
                                      "/SingleProductViewGreenArg?sku=" +
                                      this.state.prod.sku,
                                    title: this.state.prod.name,
                                  }}
                                  onClick={() =>
                                    console.log("shared successfully!")
                                  }
                                >
                                  <FaShareAlt size="16" color="green" />
                                </RWebShare>
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                        <ListGroup
                          horizontal
                          className=" p-1  thumbnaillist-group "
                          style={{ backgroundColor: "#F8F9FA" }}
                        >
                          {this.state.prod != ""
                            ? this.state.prod.productImages
                                .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                .map((image, i) => (
                                  <>
                                    <div className="p-1">
                                      <ListGroup.Item
                                        id={image.fileName}
                                        onClick={(e) =>
                                          this.OnImageChange(e, i)
                                        }
                                        className={
                                          this.state.activeButton ==
                                          image.fileName
                                            ? "active-color"
                                            : "base-class"
                                        }
                                      >
                                        <div className="m-0 p-0">
                                          {this.IsVideo(image.fileName) ==
                                          true ? (
                                            <>
                                              <img
                                                className="border-0 text-center align-top align-content-start "
                                                width="40px"
                                                height="40px"
                                                src={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/images/" +
                                                  image.posterName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                // onClick={(e) =>
                                                //   this.OnImageChange(e, i)
                                                // }
                                                style={{ objectFit: "contain" }}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                className="border-0 text-center align-top align-content-start "
                                                width="40px"
                                                height="40px"
                                                src={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/images/" +
                                                  image.fileName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                // onClick={(e) =>
                                                //   this.OnImageChange(e, i)
                                                // }
                                                style={{ objectFit: "contain" }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                    </div>
                                  </>
                                ))
                            : ""}
                        </ListGroup>
                      </Col>
                    </Row>
                  </div>
                </section>
                <div className="my-3 mx-2  ">
                  <div
                    className="my-5 "
                    dangerouslySetInnerHTML={{
                      __html: this.state.prod.description,
                    }}
                  />
                </div>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: this.state.description2Html,
                  }}
                />

                {window.location.hostname == "shop.green.com.pg" ? (
                  <>
                    {/* <section class="bg-black">
                  <div class="container">
                    <div class="row py-5">
                      <div class="col-md-12 p-5">
                        <img src="dist/images/logo-2.png" class="mw-100" />
                        <br />
                        <img
                          src="dist/images/future_energy.png"
                          class="mw-100 mt-5"
                        />
                      </div>
                    </div>
                  </div>
                </section> */}
                    <section class=" bg-white sec-pad">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-4  col-lg-2">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/fast_secure.png" />
                              <h5>
                                Fast and Secure <br />
                                Delivery
                              </h5>
                              <p>
                                Our dedicated specialists will provide advanced
                                shipment tracking, proactive notification and
                                recovery actions.
                              </p>
                            </div>
                          </div>
                          <div class="col-md-4  col-lg-2">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/warranty.png" />
                              <h5>
                                1 Year
                                <br />
                                Warranty
                              </h5>
                              <p>
                                Get Warranty Extension and protect your purchase
                                from any manufacturing defects.
                              </p>
                            </div>
                          </div>

                          <div class="col-md-4  col-lg-2">
                            <div class="feature-dsn text-center">
                              <img
                                src="dist/images/Replacement.png"
                                class="w50"
                              />
                              <h5>
                                Easy
                                <br />
                                Replacement
                              </h5>
                              <p>
                                If you have received a damaged or defective
                                product or if it is not as described, you can
                                raise a replacement request
                              </p>
                            </div>
                          </div>

                          <div class="col-md-4  col-lg-2">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/authentic_product.png" />
                              <h5>
                                100% Authentic
                                <br />
                                products
                              </h5>
                              <p>
                                provides 100% authentic products to our
                                customers by maintaining tight quality control
                                during sourcing and distribution
                              </p>
                            </div>
                          </div>

                          <div class="col-md-4  col-lg-2">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/secure_payment.png" />
                              <h5>
                                Secure
                                <br />
                                Payments
                              </h5>
                              <p>
                                To take online payments safely and reduce the
                                chance of fraud, we provide trusted payment
                                processors and gateways
                              </p>
                            </div>
                          </div>

                          <div class="col-md-4  col-lg-2">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/help_center.png" />
                              <h5>
                                Help Center
                                <br />
                                24/7
                              </h5>
                              <p>
                                Got a question? Browse our FAQs or submit your
                                query here, where you can find answers and
                                solutions to their problems
                              </p>
                            </div>
                          </div>

                          {/* <div class="pay-option-grp text-center">
                    <ul class="pay-option">
                      <li>
                        <img src="dist/images/payment/pay1.png" />
                      </li>
                      <li>
                        <img src="dist/images/payment/pay2.png" />
                      </li>
                      <li>
                        <img src="dist/images/payment/pay3.png" />
                      </li>
                      <li>
                        <img src="dist/images/payment/pay4.png" />
                      </li>
                      <li>
                        <img src="dist/images/payment/pay5.png" />
                      </li>
                      <li>
                        <img src="dist/images/payment/pay6.png" />
                      </li>
                      <li>
                        <img src="dist/images/payment/pay7.png" />
                      </li>
                      <li>
                        <img src="dist/images/payment/pay8.png" />
                      </li>
                    </ul>
                  </div> */}
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <section class="bg-white ">
                  <div class="container pt-50px">
                    <div class="row">
                      {/* LEFT SIDE */}
                      <Col
                        xs={12}
                        md={6}
                        className="text-center m-0 p-0 border-0"
                      >
                        <div style={{}}>
                          <Form.Group
                            controlId="Image"
                            className="text-center "
                          >
                            <div class="item  m-0 py-0 imgcenter">
                              <div className=" m-0 p-0 w-100 border">
                                {this.IsVideo(
                                  this.state.currentImageFileName
                                ) == true ? (
                                  <>
                                    <video
                                      preload="none"
                                      width="100%"
                                      height="320px"
                                      controls
                                      poster={
                                        this.context.store.storageBlobUrl +
                                        this.context.store
                                          .storageBlobContainerName +
                                        "/images/" +
                                        this.state.currentPosterName +
                                        this.context.store.storageSasToken
                                      }
                                    >
                                      <source
                                        src={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/images/" +
                                          this.state.currentImageFileName +
                                          this.context.store.storageSasToken
                                        }
                                        type="video/mp4"
                                      />
                                    </video>
                                  </>
                                ) : (
                                  <>
                                    <Zoom>
                                      <img
                                        className=" border-0 text-center align-middle imgcenter"
                                        style={{
                                          width: "100%",
                                          height:
                                            this.context.theme
                                              .productSingleViewProductCardImageHeight +
                                            "cm",
                                        }}
                                        src={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/images/" +
                                          this.state.currentImageFileName +
                                          this.context.store.storageSasToken
                                        }
                                      />
                                    </Zoom>
                                  </>
                                )}
                              </div>

                              {this.state.prod.shortAd ? (
                                <span className="ribbonleftgreen ribboncorners">
                                  {this.state.prod.shortAd}
                                </span>
                              ) : (
                                <></>
                              )}
                              {this.state.prod.discount > 0 ? (
                                <>
                                  {/* <span class="notify-badge-left"> */}
                                  <span
                                    // class="notify-badge-left"
                                    className="ribbonmodernright "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Discount"
                                    style={{
                                      backgroundColor: "#23B14D",
                                      color: "white",
                                    }}
                                  >
                                    {this.state.prod.discount}% off
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                              <span
                                class="notify-badge-right_share"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share me"
                                style={{
                                  cursor: "pointer",
                                  // backgroundColor: "black",
                                }}
                              >
                                <RWebShare
                                  data={{
                                    text: this.state.prod.name,
                                    url:
                                      window.location.origin +
                                      "/SingleProductViewGreenArg?sku=" +
                                      this.state.prod.sku,
                                    title: this.state.prod.name,
                                  }}
                                  onClick={() =>
                                    console.log("shared successfully!")
                                  }
                                >
                                  <FaShareAlt size="16" color="green" />
                                </RWebShare>
                              </span>
                            </div>
                          </Form.Group>
                        </div>

                        {/* Thumbnail */}
                        <ListGroup
                          horizontal
                          className=" p-1  thumbnaillist-group "
                          style={{ backgroundColor: "#F8F9FA" }}
                        >
                          {this.state.prod != ""
                            ? this.state.prod.productImages
                                .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                .map((image, i) => (
                                  <>
                                    <div className="p-1">
                                      <ListGroup.Item
                                        id={image.fileName}
                                        onClick={(e) =>
                                          this.OnImageChange(e, i)
                                        }
                                        className={
                                          this.state.activeButton ==
                                          image.fileName
                                            ? "active-color"
                                            : "base-class"
                                        }
                                      >
                                        <div className="m-0 p-0">
                                          {this.IsVideo(image.fileName) ==
                                          true ? (
                                            <>
                                              <img
                                                className="border-0 text-center align-top align-content-start "
                                                width="40px"
                                                height="40px"
                                                src={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/images/" +
                                                  image.posterName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                style={{ objectFit: "contain" }}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                className="border-0 text-center align-top align-content-start "
                                                width="40px"
                                                height="40px"
                                                src={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/images/" +
                                                  image.fileName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                // onClick={(e) =>
                                                //   this.OnImageChange(e, i)
                                                // }
                                                style={{ objectFit: "contain" }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                    </div>
                                  </>
                                ))
                            : ""}
                        </ListGroup>
                      </Col>
                      {/* RIGHT SIDE */}
                      <Col xs={12} md={6} className="px-5">
                        <div class="ps-1 ">
                          {window.location.hostname == "shop.green.com.pg" ? (
                            <>
                              <h2 class="bold_font">
                                {
                                  // this.context.subCategories.filter(
                                  //   (m) =>
                                  //     m.id ==
                                  //     this.context.shopnowProduct.subCategoryId
                                  // )[0].name
                                  this.GetSubCategoryName(
                                    this.context.shopnowProduct.subCategoryId
                                  )
                                }
                                <br />
                              </h2>
                            </>
                          ) : (
                            <></>
                          )}
                          <Stack direction="horizontal">
                            <h4 class="semibold_font pt-0">
                              {this.context.shopnowProduct.name}
                            </h4>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </Stack>
                          {this.context.theme.allProductToGetQuote == true ? (
                            <>
                              <Nav.Link
                                as={Link}
                                className="align-content-center border-0 buttonwithshadow mx-0 w-25"
                                style={{
                                  backgroundColor: "#00ff00",
                                  color: "white",
                                  fontSize: "18px",
                                  fontFamily: "Helvetica",
                                }}
                                to="/QuotePage"
                                onClick={(e) => {
                                  this.context.AddToWishList(
                                    this.context.shopnowProduct
                                  );
                                }}
                              >
                                Get Quote ...
                              </Nav.Link>
                            </>
                          ) : (
                            <>
                              {this.state.prod.discount > 0 ? (
                                <>
                                  <div class="row pt-3 br-btm">
                                    <div class="col-auto">
                                      <h2>
                                        <span class="semibold_font">
                                          {Math.round(
                                            Number(
                                              this.state.prod.mrp -
                                                (this.state.prod.mrp *
                                                  this.state.prod.discount) /
                                                  100
                                            ),
                                            0
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </span>
                                      </h2>
                                      <p class="bold cl-grey">
                                        Inclusive of all taxes
                                      </p>
                                    </div>
                                    <div class="col">
                                      <p class="mb-0">
                                        MRP{" "}
                                        <del>
                                          {Math.round(
                                            this.state.prod.mrp,
                                            0
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </del>
                                      </p>
                                      <h5 class="bold cl-gra-green">
                                        ({this.state.prod.discount}% OFF)
                                      </h5>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div class="row pt-3 br-btm">
                                    <div class="col-auto">
                                      <h2>
                                        <span class="semibold_font">
                                          {Math.round(
                                            Number(
                                              this.state.prod.mrp -
                                                (this.state.prod.mrp *
                                                  this.state.prod.discount) /
                                                  100
                                            ),
                                            0
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </span>
                                      </h2>
                                      <p class="bold cl-grey">
                                        Inclusive of all taxes
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          className="py-3"
                          dangerouslySetInnerHTML={{
                            __html: this.state.prod.description,
                          }}
                        />
                        {this.context.theme.allProductToGetQuote == true ? (
                          <></>
                        ) : (
                          <>
                            <div class="row g-3 align-items-center">
                              <div className="my-3">
                                <div class="row">
                                  {this.context.myCart.filter(
                                    (f) => f.prod.id == this.state.prod.id
                                  ).length == 1 ? (
                                    <>
                                      <div class="col-auto">
                                        <h6 for="" class="col-form-label bold">
                                          Quantity :{" "}
                                        </h6>
                                      </div>
                                      <div class="col-auto cartTable">
                                        <Row>
                                          <Col md={8}>
                                            <IncDecCounter
                                              intvalue={
                                                this.context.myCart.filter(
                                                  (f) =>
                                                    f.prod.id ==
                                                    this.state.prod.id
                                                )[0].qty
                                              }
                                              valueChangeCallback={(e) => {
                                                if (e === "") {
                                                  this.setState({ qty: 0 });
                                                } else if (e === 0) {
                                                  this.setState({ qty: 1 });
                                                  this.context.deleteItemOnMyCart(
                                                    this.state.prod
                                                  );
                                                  this.context.forceUpdateCartView();
                                                } else {
                                                  this.context.UpdateQtyToCart(
                                                    this.state.prod,
                                                    e
                                                  );
                                                  this.context.forceUpdateCartView();
                                                }
                                              }}
                                            ></IncDecCounter>
                                          </Col>
                                          <Col md={4}></Col>
                                        </Row>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div class="col-auto">
                                        <h6 for="" class="col-form-label bold">
                                          Quantity :{" "}
                                        </h6>
                                      </div>
                                      <div
                                        class="col-auto"
                                        autoComplete="new-off"
                                      >
                                        <Form.Control
                                          type="number"
                                          // min={1}
                                          required
                                          autoComplete="new-off"
                                          role="presentation"
                                          className="form-control product_quantity"
                                          value={this.state.qty}
                                          style={{
                                            fontSize: "16px",
                                          }}
                                          onChange={this.handleQtyChange}
                                        />
                                      </div>
                                      <div class="col-auto">
                                        {window.location.hostname ==
                                        "shop.green.com.pg" ? (
                                          <>
                                            <Button
                                              className="btn-green d-inline-block"
                                              onClick={this.AddToCart}
                                              disabled={
                                                this.state.qty <= 0 ||
                                                this.state.qty == ""
                                              }
                                            >
                                              ADD TO CART
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            <Button
                                              style={{
                                                backgroundColor:
                                                  this.context.theme
                                                    .mainNavBarBackgroundColor,
                                                color:
                                                  this.context.theme
                                                    .mainNavBarTextColor,
                                              }}
                                              onClick={this.AddToCart}
                                              disabled={
                                                this.state.qty <= 0 ||
                                                this.state.qty == ""
                                              }
                                            >
                                              ADD TO CART
                                            </Button>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </div>
                    <div
                      className="py-1"
                      dangerouslySetInnerHTML={{
                        __html: this.state.description2Html,
                      }}
                    />
                  </div>
                </section>

                {this.context.shopnowProduct.relatedProductsSkuListByCsv !=
                  undefined &&
                this.context.shopnowProduct.relatedProductsSkuListByCsv !=
                  "" ? (
                  <>
                    <section class="bg-white">
                      <div class="container">
                        <div class="row py-2">
                          <div class="col-md-12 mx-5">
                            <h2 class="semibold_font pt-2">Related Products</h2>
                            <div className="row justify-content-around py-2 relatedProductDsn">
                              {this.context.shopnowProduct.relatedProductsSkuListByCsv
                                .split(",")
                                .map((sku) => (
                                  <>
                                    <div
                                      className="card p-1 mb-2 m-0 "
                                      style={{
                                        width:
                                          this.context.theme.productCardWidth +
                                          "cm",
                                        backgroundColor:
                                          this.context.theme
                                            .productCardBackgroundColor,
                                        borderColor:
                                          this.context.theme
                                            .productCardBorderColor,
                                      }}
                                    >
                                      <div className="p-0 m-0 border">
                                        {this.context.prods.filter(
                                          (f) => f.sku == sku
                                        ).length == 1 ? (
                                          <>
                                            <ProductCard
                                              prod={
                                                this.context.prods.filter(
                                                  (f) => f.sku == sku
                                                )[0]
                                              }
                                            ></ProductCard>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  <></>
                )}
                {window.location.hostname == "shop.green.com.pg" ? (
                  <>
                    <section class=" bg-white sec-pad">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-2  ">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/fast_secure.png" />
                              <h5>
                                Fast and Secure <br />
                                Delivery
                              </h5>
                              <p>
                                Our dedicated specialists will provide advanced
                                shipment tracking, proactive notification and
                                recovery actions.
                              </p>
                            </div>
                          </div>
                          <div class="col-md-2  ">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/warranty.png" />
                              <h5>
                                1 Year
                                <br />
                                Warranty
                              </h5>
                              <p>
                                Get Warranty Extension and protect your purchase
                                from any manufacturing defects.
                              </p>
                            </div>
                          </div>

                          <div class="col-md-2  ">
                            <div class="feature-dsn text-center">
                              <img
                                src="dist/images/Replacement.png"
                                class="w50"
                              />
                              <h5>
                                Easy
                                <br />
                                Replacement
                              </h5>
                              <p>
                                If you have received a damaged or defective
                                product or if it is not as described, you can
                                raise a replacement request
                              </p>
                            </div>
                          </div>

                          <div class="col-md-2  ">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/authentic_product.png" />
                              <h5>
                                100% Authentic
                                <br />
                                products
                              </h5>
                              <p>
                                provides 100% authentic products to our
                                customers by maintaining tight quality control
                                during sourcing and distribution
                              </p>
                            </div>
                          </div>

                          <div class="col-md-2  ">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/secure_payment.png" />
                              <h5>
                                Secure
                                <br />
                                Payments
                              </h5>
                              <p>
                                To take online payments safely and reduce the
                                chance of fraud, we provide trusted payment
                                processors and gateways
                              </p>
                            </div>
                          </div>

                          <div class="col-md-2  ">
                            <div class="feature-dsn text-center">
                              <img src="dist/images/help_center.png" />
                              <h5>
                                Help Center
                                <br />
                                24/7
                              </h5>
                              <p>
                                Got a question? Browse our FAQs or submit your
                                query here, where you can find answers and
                                solutions to their problems
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const Users = (props) => {
  const [searchParams] = useSearchParams();
  //console.log(searchParams.get("sku")); // 'name'
  props.sku(searchParams.get("sku"));

  return <div></div>;
};

export class SingleProductViewGreenArg extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      sku: "",
      prodloaded: false,
    };
  }

  StoreProductSku = (sku) => {
    // console.log(sku);
    this.setState({ sku: sku });
  };

  render() {
    if (
      this.state.sku != "" &&
      this.state.sku != undefined &&
      this.state.prodloaded == false
    ) {
      if (this.context.prodsAll && this.context.prodsAll.length != 0) {
        this.setState({ prodloaded: true });
        // console.log(this.state.sku);
        var prods = this.context.prodsAll.filter(
          (f) => f.sku == this.state.sku
        );
        if (prods.length > 0) {
          this.context.StoreShopnowProduct(prods[0]);
          //this.GetProductById(prods[0].id);
        }
      }
    }
    if (this.context.shopnowProduct == "")
      return (
        <>
          {this.state.prodloaded == false ? (
            <>
              <Users sku={this.StoreProductSku}></Users>
            </>
          ) : (
            <></>
          )}
        </>
      );
    return (
      <>
        <ProductSingleProductView1></ProductSingleProductView1>
      </>
    );
  }
}

export class ProductSingleProductViewWrapper extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  componentDidUpdate = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <>
        <ProductSingleProductView1></ProductSingleProductView1>
      </>
    );
  }
}
