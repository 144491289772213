import { render } from "@testing-library/react";
import React, { Component, useContext, useState } from "react";
import { BsFillHeartFill, BsHeart, BsCart2, BsBag } from "react-icons/bs";
import { ProductSingleProductViewMobile } from "./ProductSingleProductViewMobile";
import IncDecCounterMobile from "./IncDecCounterControlMobile";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import { Navigate } from "react-router-dom";

import {
  Form,
  Nav,
  Button,
  Modal,
  ListGroup,
  Badge,
  FloatingLabel,
  Row,
  Col,
  Table,
  InputGroup,
  Container as div,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import CartContext from "./CartContext";

export default class ProductCardMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      qty: "1",
      weightAndPrice: "",
      showProductSingleViewModal: false,
      showProductSingleViewPage: false,
    };
  }
  static weightString = "";
  static priceString = "";

  componentDidMount() {}

  addToCartSubmit = (_) => {
    this.context.addToMyCart({
      prod: this.props.prod,
      qty: this.state.qty,
    });
    //this.context.forceUpdateCartView();
  };

  OnClickShopNow = () => {
    if (this.props.hideSearchModal != undefined) this.props.hideSearchModal();
    this.context.StoreShopnowProduct(this.props.prod);
  };

  handleQtyChange = (value) => {
    this.setState({ qty: value });
  };

  getFullWidthProductName = (name) => {
    var len = name.length;
    var whitespacerequired = 60 - len;
    for (let i = 0; i < whitespacerequired; i++) {
      name += "\u00A0";
    }

    return name;
  };

  printwhitespace = () => {
    return "";
  };

  OpenSingleViewModal = () => {
    this.setState({ showProductSingleViewModal: true });
  };

  editModalClose = () => {
    this.setState({ showProductSingleViewModal: false }, () => {
      this.context.forceUpdateCartView();
      this.context.SetRefreshIncDecControl(true);
    });
  };

  IsAlreadyInWishlist = (prodId) => {
    var res = this.context.wishListProductIds.filter(
      (f) => f.prodId == this.props.prod.id
    );
    if (res.length > 0) return true;
    else return false;
  };
  IsVideo(filename) {
    if (filename) {
      var f = filename.split(".").pop();
      if (f == "mp4") return true;
    }
    return false;
  }
  render() {
    if (this.state.showProductSingleViewPage == true) {
      if (this.props.parent == "SearchView") {
        this.context.SetRefreshSinglePageView(true);
        this.props.hideSearchModal();
      }
      return <Navigate to="/ProductSingleProductView1"></Navigate>;
    }

    return (
      <>
        {window.location.hostname == "shop.green.com.pg" ? (
          <>
            <div
              style={{
                backgroundColor: this.context.theme.productCardBackgroundColor,
              }}
              className="m-0 p-0 "
              width="100%"
            >
              {this.state.showProductSingleViewModal == true ? (
                <ProductSingleProductViewMobile
                  show={this.state.showProductSingleViewModal}
                  onHide={this.editModalClose}
                  prod={this.props.prod}
                />
              ) : (
                ""
              )}
              <Form
                className="m-0 p-0"
                style={{
                  backgroundColor:
                    this.context.theme.productCardBackgroundColor,
                }}
              >
                <Form.Group className="mb-0 " controlId="formBasicEmail">
                  <>
                    <div class="item m-0 p-0 imgcenter ">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          (this.props.prod.imgFileName == undefined
                            ? this.props.prod.productImages != undefined &&
                              this.props.prod.productImages.length > 0
                              ? this.props.prod.productImages[0].fileName
                              : "noimage.jpg"
                            : this.props.prod.imgFileName) +
                          this.context.store.storageSasToken
                        }
                        className=""
                        alt="..."
                        //onClick={(e) => this.OpenSingleViewModal(e)}
                        style={{
                          // cursor: "pointer",
                          width: "100%",
                          height:
                            this.context.theme.productCardImageHeightMobile +
                            "cm",
                          objectFit: "contain",
                        }}
                      ></img>
                    </div>
                  </>
                  <br></br>
                  <label
                    className="mx-0 p-0 mb-0 productNamewrap  "
                    style={{
                      width: "100%",
                      height:
                        this.context.theme.productNameHeightOnProductCard +
                        "cm",
                      // cursor: "pointer",
                      // fontFamily: "Helvetica",
                      fontWeight: "bold",
                      fontSize: 12,
                      textAlign:
                        this.context.theme.productNameAlignOnProductCard,
                      color: this.context.theme.productCardTextColor,
                    }}
                    // onClick={(e) => this.OpenSingleViewModal(e)}
                  >
                    <h2 class="bold_font">
                      {
                        this.context.subCategories.filter(
                          (m) => m.id == this.props.prod.subCategoryId
                        )[0].name
                      }
                    </h2>
                    <h5>{this.props.prod.name}</h5>
                  </label>
                  {this.props.prod.shortAd ? (
                    <span className="ribbonleftgreen ribboncorners">
                      {this.props.prod.shortAd}
                    </span>
                  ) : (
                    <></>
                  )}
                  {this.props.prod.discount > 0 ? (
                    <>
                      {/* <span class="notify-badge-left"> */}
                      <span
                        // class="notify-badge-left"
                        className="ribbonmodernright "
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Discount"
                        style={{
                          backgroundColor: "#23B14D",
                          color: "white",
                        }}
                      >
                        {this.props.prod.discount}% off
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  <Form.Label
                    className="text-center text-dark "
                    style={{
                      width: "100%",
                      // color: this.context.theme.productCardTextColor,
                    }}
                  >
                    {this.props.prod.discount > 0 ? (
                      <>
                        <div class="row pt-3 ">
                          <div class="">
                            <h4>
                              <span class="semibold_font">
                                {Math.round(
                                  Number(
                                    this.props.prod.mrp -
                                      (this.props.prod.mrp *
                                        this.props.prod.discount) /
                                        100
                                  ),
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </span>
                              &nbsp;&nbsp;
                              <span
                                className=""
                                style={{
                                  color:
                                    this.context.theme.productCardTextColor,
                                }}
                              >
                                <strike>
                                  {Number(this.props.prod.mrp).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </strike>
                              </span>
                            </h4>
                            {/* <h5 class="bold cl-gra-green">
                              ({this.props.prod.discount}% OFF)
                            </h5> */}
                            {/* <p class="bold cl-grey">Inclusive of all taxes</p> */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="row pt-3 ">
                          <div class="">
                            <h4>
                              <span class="semibold_font">
                                {Math.round(
                                  Number(
                                    this.props.prod.mrp -
                                      (this.props.prod.mrp *
                                        this.props.prod.discount) /
                                        100
                                  ),
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </span>
                            </h4>
                            {/* <p class="bold cl-grey">Inclusive of all taxes</p> */}
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Label>
                  <div class="text-center ">
                    <div className="single-item">
                      <Nav.Link
                        className="btn-dgn-2"
                        href={
                          window.location.origin +
                          "/SingleProductViewGreenArg?sku=" +
                          this.props.prod.sku
                        }
                      >
                        Shop now{" "}
                      </Nav.Link>
                    </div>
                  </div>
                  <span
                    class="notify-badge-right_share"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Share me"
                    style={{
                      cursor: "pointer",
                      // backgroundColor: "black",
                    }}
                  >
                    <RWebShare
                      data={{
                        text: this.props.prod.name,
                        url:
                          window.location.origin +
                          "/SingleProductViewGreenArg?sku=" +
                          this.props.prod.sku,
                        title: this.props.prod.name,
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <FaShareAlt size="13" color="green" />
                    </RWebShare>
                  </span>
                  <br />
                </Form.Group>
              </Form>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                backgroundColor: this.context.theme.productCardBackgroundColor,
                borderColor: this.context.theme.productCardBorderColor,
              }}
              className="p-0 m-0 border-0 "
              width="100%"
            >
              {this.state.showProductSingleViewModal == true ? (
                <ProductSingleProductViewMobile
                  show={this.state.showProductSingleViewModal}
                  onHide={this.editModalClose}
                  prod={this.props.prod}
                />
              ) : (
                ""
              )}
              {this.props.displayType == "list" ? (
                <>
                  {this.props.prod.shortAd ? (
                    <span
                      className="ribbonleft ribboncorners"
                      style={{
                        backgroundColor:
                          this.context.theme.mainNavBarBackgroundColor,
                        color: this.context.theme.mainNavBarTextColor,
                      }}
                    >
                      {this.props.prod.shortAd}
                    </span>
                  ) : (
                    <></>
                  )}
                  {this.props.prod.discount > 0 ? (
                    <>
                      <span
                        // class="notify-badge-left"
                        className="ribbonmodernright"
                        style={{
                          cursor: "pointer",
                          // backgroundColor:
                          //   this.context.theme.mainNavBarBackgroundColor,
                          backgroundColor: "red",
                          color: this.context.theme.mainNavBarTextColor,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.prod.discount}% off
                      </span>
                    </>
                  ) : (
                    ""
                  )}

                  <Table className="border-0 p-0 m-0">
                    <tr>
                      <td width={"35%"}>
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            (this.props.prod.imgFileName == undefined
                              ? this.props.prod.productImages != undefined &&
                                this.props.prod.productImages.length > 0
                                ? this.props.prod.productImages[0].fileName
                                : "noimage.jpg"
                              : this.props.prod.imgFileName) +
                            this.context.store.storageSasToken
                          }
                          className="card-img-top CornersRounded"
                          alt="..."
                          onClick={(e) => this.OpenSingleViewModal(e)}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            // height:
                            //   this.context.theme.productCardImageHeightMobile +
                            //   "cm",
                            objectFit: "contain",
                          }}
                        ></img>
                      </td>
                      <td width={"65%"}>
                        <h5
                          style={{
                            width: "100%",
                            color: this.context.theme.productCardTextColor,
                            fontFamily: "Helvetica",
                          }}
                        >
                          <b>{this.props.prod.name}</b>
                        </h5>

                        <div
                          className="text-start p-0 m-0"
                          style={{
                            width: "100%",
                            color: this.context.theme.productCardTextColor,
                            //fontFamily: "Helvetica",
                            fontFamily: "monospace",
                          }}
                        >
                          {this.props.prod.discount > 0 ? (
                            <>
                              <div>
                                <span
                                  style={{
                                    fontSize:
                                      this.context.theme
                                        .priceFontSizeOnProductCardMobile +
                                      "px",
                                    // color: this.context.theme.productCardTextColor,
                                  }}
                                >
                                  <b
                                    className="costfont"
                                    style={{
                                      fontSize:
                                        this.context.theme
                                          .priceFontSizeOnProductCardMobile +
                                        "px",
                                      // color: this.context.theme.productCardTextColor,
                                    }}
                                  >
                                    &nbsp;
                                    {Math.round(
                                      Number(
                                        this.props.prod.mrp -
                                          (this.props.prod.mrp *
                                            this.props.prod.discount) /
                                            100
                                      ),
                                      0
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </b>{" "}
                                  &nbsp;
                                  <span
                                    className=" "
                                    style={{
                                      color: "grey",
                                      //this.context.theme.productCardTextColor,
                                    }}
                                  >
                                    {/* M.R.P  */}
                                    <strike>
                                      {Math.round(
                                        Number(this.props.prod.mrp)
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </strike>
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <span
                                  className="text-center costfont "
                                  style={{
                                    fontSize:
                                      this.context.theme
                                        .priceFontSizeOnProductCardMobile +
                                      "px",
                                  }}
                                >
                                  <small className=""> </small>
                                  {Math.round(
                                    this.props.prod.mrp,
                                    0
                                  ).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        <table className="p-0 m-0 ">
                          <tbody>
                            <tr>
                              <td className="text-center">
                                <>
                                  {this.context.myCart.filter(
                                    (f) => f.prod.id == this.props.prod.id
                                  ).length == 1 ? (
                                    <>
                                      <div className=" p-2 cartTable">
                                        <IncDecCounterMobile
                                          intvalue={
                                            this.context.myCart.filter(
                                              (f) =>
                                                f.prod.id == this.props.prod.id
                                            )[0].qty
                                          }
                                          valueChangeCallback={(e) => {
                                            this.context.UpdateQtyToCart(
                                              this.props.prod,
                                              e
                                            );
                                            this.context.forceUpdateCartView();
                                          }}
                                        ></IncDecCounterMobile>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className=" p-2">
                                        <Button
                                          className="align-content-center w-100 border-0 buttonRounded buttonwithshadow"
                                          onClick={this.addToCartSubmit}
                                          style={{
                                            backgroundColor:
                                              this.context.theme
                                                .mainNavBarBackgroundColor,
                                            color:
                                              this.context.theme
                                                .mainNavBarTextColor,
                                            // fontWeight: "bolder",
                                          }}
                                        >
                                          Add to &nbsp;
                                          {this.context.theme.cartIcon ==
                                          "Cart" ? (
                                            <>
                                              <BsCart2
                                                size={"20px"}
                                                style={{
                                                  backgroundColor:
                                                    this.context.theme
                                                      .mainNavBarBackgroundColor,
                                                  color:
                                                    this.context.theme
                                                      .mainNavBarTextColor,
                                                }}
                                              ></BsCart2>
                                            </>
                                          ) : (
                                            <>
                                              <BsBag
                                                size={"20px"}
                                                style={{
                                                  backgroundColor:
                                                    this.context.theme
                                                      .mainNavBarBackgroundColor,
                                                  color:
                                                    this.context.theme
                                                      .mainNavBarTextColor,
                                                }}
                                              ></BsBag>
                                            </>
                                          )}
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </>
                              </td>
                              <td>
                                <label className="mx-0 p-0 mb-0  verticalaligncentertd ">
                                  <div>
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Add to Wishlist"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        this.context.AddToWishList(
                                          this.props.prod
                                        );
                                        this.setState({ msgboxOpen: true });
                                        this.setState({
                                          msgboxTitle: "Information",
                                        });
                                        this.setState({
                                          msgboxMessage: "Added to wishlist",
                                        });
                                      }}
                                    >
                                      {this.IsAlreadyInWishlist(
                                        this.props.prod.id
                                      ) == false ? (
                                        <>
                                          <BsHeart
                                            size="16"
                                            className=""
                                            style={{
                                              cursor: "pointer",
                                              backgroundColor:
                                                "transparent !important",
                                              color:
                                                this.context.theme
                                                  .productCardTextColor,
                                            }}
                                          ></BsHeart>
                                        </>
                                      ) : (
                                        <>
                                          <BsFillHeartFill
                                            size="16"
                                            style={{
                                              backgroundColor:
                                                "transparent !important",
                                              color: "red",
                                            }}
                                          ></BsFillHeartFill>
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </label>
                                {/* share */}
                                {this.props.prod.sku ? (
                                  <>
                                    <span
                                      class="notify-badge-right_share"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Share me"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor:
                                          this.context.theme
                                            .mainNavBarBackgroundColor,
                                        color:
                                          this.context.theme
                                            .mainNavBarTextColor,
                                      }}
                                    >
                                      <RWebShare
                                        data={{
                                          text: this.props.prod.name,
                                          url:
                                            window.location.origin +
                                            "/SingleProductViewGreenArg?sku=" +
                                            this.props.prod.sku,
                                          title: this.props.prod.name,
                                        }}
                                        onClick={() =>
                                          console.log("shared successfully!")
                                        }
                                      >
                                        <FaShareAlt size="13" />
                                      </RWebShare>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </Table>
                </>
              ) : (
                <>
                  <Form
                    className="mb-0 border-0"
                    controlId="formBasicEmail"
                    // style={{
                    //   backgroundColor:
                    //     this.context.theme.productCardBackgroundColor,
                    // }}
                  >
                    <Form.Group className="mb-0 " controlId="formBasicEmail">
                      <>
                        <div class="item-dark m-0 p-0 imgcenter ">
                          {this.props.prod.discount > 0 ? (
                            <>
                              <span
                                // class="notify-badge-left"
                                className="ribbonmodernright"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                  fontSize: "12px",
                                }}
                              >
                                {this.props.prod.discount}% off
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {this.props.prod.shortAd ? (
                            <span
                              className="ribbonleft ribboncorners"
                              style={{
                                backgroundColor:
                                  this.context.theme.mainNavBarBackgroundColor,
                                color: this.context.theme.mainNavBarTextColor,
                              }}
                            >
                              {this.props.prod.shortAd}
                            </span>
                          ) : (
                            <></>
                          )}
                          {this.IsVideo(this.props.prod.imgFileName) == true ? (
                            <>
                              <video
                                preload="none"
                                width="100%"
                                // playsinline
                                // controls
                                className="TopCornerRounded"
                                poster={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  this.props.prod.posterName +
                                  this.context.store.storageSasToken
                                }
                                onClick={(e) => {
                                  if (
                                    this.props.parent != "singleview" &&
                                    this.props.parent == undefined &&
                                    this.context.theme.allProductToGetQuote !=
                                      true
                                  )
                                    this.OpenSingleViewModal(e);
                                }}
                              >
                                <source
                                  src={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    this.props.prod.imgFileName +
                                    this.context.store.storageSasToken
                                  }
                                  type="video/mp4"
                                />
                              </video>
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  (this.props.prod.imgFileName == undefined
                                    ? this.props.prod.productImages !=
                                        undefined &&
                                      this.props.prod.productImages.length > 0
                                      ? this.props.prod.productImages[0]
                                          .fileName
                                      : "noimage.jpg"
                                    : this.props.prod.imgFileName) +
                                  this.context.store.storageSasToken
                                }
                                className="card-img-top TopCornerRounded"
                                alt="..."
                                onClick={(e) => {
                                  // if (this.props.parent != "singleview")
                                  //   this.OnClickShopNow(e);
                                  if (
                                    this.props.parent != "singleview" &&
                                    this.props.parent == undefined &&
                                    this.context.theme.allProductToGetQuote !=
                                      true
                                  )
                                    this.OpenSingleViewModal(e);
                                }}
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  height:
                                    this.context.theme
                                      .productCardImageHeightMobile + "cm",
                                  objectFit: "contain",
                                }}
                              ></img>
                            </>
                          )}
                        </div>
                      </>
                      <br></br>
                      <label
                        className="mx-0 p-0 mb-0 productNamewrap  "
                        style={{
                          width: "100%",
                          height:
                            this.context.theme.productNameHeightOnProductCard +
                            "cm",
                          cursor: "pointer",
                          fontFamily: "Helvetica",
                          fontWeight: "bold",
                          fontSize: 12,
                          textAlign:
                            this.context.theme.productNameAlignOnProductCard,
                          color: this.context.theme.productCardTextColor,
                        }}
                      >
                        <div>
                          {this.props.prod.name} &nbsp;&nbsp;
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add to Wishlist"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              this.context.AddToWishList(this.props.prod);
                              this.setState({ msgboxOpen: true });
                              this.setState({
                                msgboxTitle: "Information",
                              });
                              this.setState({
                                msgboxMessage: "Added to wishlist",
                              });
                            }}
                          >
                            {this.IsAlreadyInWishlist(this.props.prod.id) ==
                            false ? (
                              <>
                                <BsHeart
                                  size="16"
                                  className=""
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "transparent !important",
                                    color:
                                      this.context.theme.productCardTextColor,
                                  }}
                                ></BsHeart>
                              </>
                            ) : (
                              <>
                                <BsFillHeartFill
                                  size="16"
                                  style={{
                                    backgroundColor: "transparent !important",
                                    color: "red",
                                  }}
                                ></BsFillHeartFill>
                              </>
                            )}
                          </span>
                        </div>
                      </label>

                      {/* share */}
                      {this.props.prod.sku ? (
                        <>
                          <span
                            class="notify-badge-right_share"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Share me"
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          >
                            <RWebShare
                              data={{
                                text: this.props.prod.name,
                                url:
                                  window.location.origin +
                                  "/SingleProductViewGreenArg?sku=" +
                                  this.props.prod.sku,
                                title: this.props.prod.name,
                              }}
                              onClick={() =>
                                console.log("shared successfully!")
                              }
                            >
                              <FaShareAlt size="13" />
                            </RWebShare>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}

                      {this.context.theme.allProductToGetQuote == true ? (
                        <></>
                      ) : (
                        <>
                          <Form.Label
                            className="text-center"
                            style={{
                              width: "100%",
                              color: this.context.theme.productCardTextColor,
                              fontFamily: "Helvetica",
                            }}
                          >
                            {this.props.prod.discount > 0 ? (
                              <>
                                <div>
                                  <span
                                    style={{
                                      fontSize:
                                        this.context.theme
                                          .priceFontSizeOnProductCardMobile +
                                        "px",
                                      // color: this.context.theme.productCardTextColor,
                                    }}
                                  >
                                    <b
                                      className="costfont"
                                      style={{
                                        fontSize:
                                          this.context.theme
                                            .priceFontSizeOnProductCardMobile +
                                          "px",
                                        // color: this.context.theme.productCardTextColor,
                                      }}
                                    >
                                      &nbsp;
                                      {Math.round(
                                        Number(
                                          this.props.prod.mrp -
                                            (this.props.prod.mrp *
                                              this.props.prod.discount) /
                                              100
                                        ),
                                        0
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </b>{" "}
                                    &nbsp;
                                    <span
                                      className=" "
                                      style={{
                                        color:
                                          this.context.theme
                                            .productCardTextColor,
                                      }}
                                    >
                                      {/* M.R.P  */}
                                      <strike>
                                        {Math.round(
                                          Number(this.props.prod.mrp)
                                        ).toLocaleString(
                                          this.context.storeSettings
                                            .defaultLocale,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                            style: "currency",
                                            currency:
                                              this.context.storeSettings
                                                .defaultCurrency,
                                          }
                                        )}
                                      </strike>
                                    </span>
                                  </span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <span
                                    className="text-center costfont fw-bold"
                                    style={{
                                      fontSize:
                                        this.context.theme
                                          .priceFontSizeOnProductCardMobile +
                                        "px",
                                      // color: this.context.theme.productCardTextColor,
                                    }}
                                  >
                                    <small className=""> </small>
                                    {Math.round(
                                      this.props.prod.mrp,
                                      0
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </span>
                                </div>
                              </>
                            )}
                          </Form.Label>
                          <br></br>
                        </>
                      )}

                      <table className=" fullwidth">
                        <tr>
                          <td className="text-center">
                            {this.props.prod.productVariantType == 2 ? (
                              <>
                                <div className=" p-2">
                                  <Button
                                    className="align-content-center w-100 border buttonwithshadow buttonRounded "
                                    style={{
                                      backgroundColor:
                                        this.context.theme
                                          .mainNavBarBackgroundColor,
                                      color:
                                        this.context.theme.mainNavBarTextColor,
                                    }}
                                    onClick={(e) => this.OpenSingleViewModal(e)}
                                  >
                                    <b>View</b>
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <>
                                {this.context.myCart.filter(
                                  (f) => f.prod.id == this.props.prod.id
                                ).length == 1 ? (
                                  <>
                                    <div className=" p-2 cartTable">
                                      <IncDecCounterMobile
                                        intvalue={
                                          this.context.myCart.filter(
                                            (f) =>
                                              f.prod.id == this.props.prod.id
                                          )[0].qty
                                        }
                                        valueChangeCallback={(e) => {
                                          this.context.UpdateQtyToCart(
                                            this.props.prod,
                                            e
                                          );
                                          this.context.forceUpdateCartView();
                                        }}
                                      ></IncDecCounterMobile>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {this.context.theme.allProductToGetQuote ==
                                    true ? (
                                      <>
                                        <div className="w-100 text-center">
                                          <Button className="m-0 p-0 border-0">
                                            <Nav.Link
                                              style={{
                                                backgroundColor: "#00ff00",
                                                color: "black",
                                                fontSize: "14px",
                                                fontFamily: "Helvetica",
                                              }}
                                              onClick={(e) => {
                                                if (
                                                  this.context.userData
                                                    .loginStatus != "active"
                                                ) {
                                                  this.context.ShowUserLoginNew(
                                                    true
                                                  );
                                                } else {
                                                  this.setState({
                                                    showProductSingleViewPage: true,
                                                  });
                                                  this.context.StoreShopnowProduct(
                                                    this.props.prod
                                                  );
                                                }
                                              }}
                                            >
                                              Explore more...{" "}
                                            </Nav.Link>
                                          </Button>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className=" p-2">
                                          <Button
                                            className="align-content-center w-100 border-0 buttonRounded buttonwithshadow"
                                            onClick={this.addToCartSubmit}
                                            style={{
                                              backgroundColor:
                                                this.context.theme
                                                  .mainNavBarBackgroundColor,
                                              color:
                                                this.context.theme
                                                  .mainNavBarTextColor,
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            Add to &nbsp;
                                            {this.context.theme.cartIcon ==
                                            "Cart" ? (
                                              <>
                                                <BsCart2
                                                  size={"20px"}
                                                  style={{
                                                    backgroundColor:
                                                      this.context.theme
                                                        .mainNavBarBackgroundColor,
                                                    color:
                                                      this.context.theme
                                                        .mainNavBarTextColor,
                                                  }}
                                                ></BsCart2>
                                              </>
                                            ) : (
                                              <>
                                                <BsBag
                                                  size={"20px"}
                                                  style={{
                                                    backgroundColor:
                                                      this.context.theme
                                                        .mainNavBarBackgroundColor,
                                                    color:
                                                      this.context.theme
                                                        .mainNavBarTextColor,
                                                  }}
                                                ></BsBag>
                                              </>
                                            )}
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      </table>
                    </Form.Group>
                  </Form>
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
