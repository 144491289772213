import React, { Component, useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import Slider from "react-slick";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import axios from "axios";
import { MySEO } from "./MySEO";
import { Blogs } from "./Blogs";
import { Navigate } from "react-router-dom";
import { HomeBannerVideo } from "./HomeAccessories";
import { FaUserCog } from "react-icons/fa";

import {
  Accordion,
  Row,
  Col,
  Modal,
  Form,
  Offcanvas,
  Table,
  Stack,
  FloatingLabel,
  NavLink,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ProductsList } from "./ProductsList";
import ProductCard from "./ProductCard";
import CartContext from "./CartContext";
import { LoadSpinner } from "./LoadSpinner";

import { SampleNextArrow } from "./HomeAccessories";
import { SamplePrevArrow } from "./HomeAccessories";
import { HomeCountersDisplay } from "./HomeAccessories";
import { Testimonial } from "./HomeAccessories";
import { CertificatesSlider } from "./HomeAccessories";
import { ClientsSlider } from "./HomeAccessories";
import { Vlogs } from "./HomeAccessories";
import { ConsumerMainCarousel } from "./HomeAccessories";
import { ProductGroupCarousel } from "./HomeAccessories";
import { LanguageSelection } from "./LanguageSelection";
import { AboutUs } from "./aboutus";

export class Home extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      cookieBanner: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: true,
      reload: false,
      firsload: false,
      showEnterpriseStoreModel: "false",
    };
    this.myRef = React.createRef();
  }

  executeScroll = () => this.myRef.scrollIntoView();

  OnCloseCookieBanner = (value) => {
    this.setState({ cookieBanner: value });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  fakeRequest = (timeout) => {
    return new Promise((resolve) =>
      setTimeout(
        () => resolve(),
        //this.context.storeSettings.homePageLoadingTimeInSecond
        timeout
      )
    );
  };

  GetProductlistDivWidth = () => {
    var elem = document.getElementById("productlistdiv");
    if (elem) {
      var rect = elem.getBoundingClientRect();
      return rect.width;
    }
    return 1500;
  };

  hideEnterpriseStoreModel = () => {
    this.setState({ showEnterpriseStoreModel: false });
  };

  GetMyStoreDetails = (pinCode) => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetMyStoreDetailsByPincodeForEnterprise/" +
        this.context.storeId +
        "/" +
        pinCode
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert(
            "This Application is not active yet. Please contact your Vendor!"
          );
        } else {
          if (new Date(data.storeEnd) > new Date() == false) {
            alert("Store is Inactive. Please contact your Vendor!");
            return;
          }

          this.context.setStoreId(data.id);
          this.context.SetStore(data);
          this.setState({ storeId: data.id }, () => {
            this.setState({ showEnterpriseStoreModel: false });
            this.context.SetDeliveryPinCode(pinCode);
            this.context.GetStoreSettings();
            this.context.GetDisplayCounter();
            this.context.GetVisitorsCount();
            this.context.GetMainCarousel(1); //1-desktop 2-Mobile
            this.context.GetOrderStatusTypes();
            this.context.getMainCategories();
            this.context.getSubCategories();
            this.context.getBrands();
            this.context.GetMainMenus();
            this.context.GetTop5Ads();
            this.context.GetAllProducts();
            this.context.GetProductGroups();
            this.context.getShippingDetails();
            this.context.getTaxDetails();
            this.context.GetProductDataType();
            this.context.StoreMyIpAddress();
          });
        }
      })
      .catch((error) => {
        alert("We are sorry as We don't have delivery to this Location!");
      });
  };

  LoadEnterprise = () => {
    if (
      this.context.store &&
      this.context.store.storeTypeId == this.context.storeType.Enterprise &&
      this.context.deliveryPincode == ""
    ) {
      this.setState({ showEnterpriseStoreModel: true });
    }
  };

  ShopNow = (e) => {
    this.setState({ showEnterpriseStoreModel: true });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.firsload == false) {
      this.state.firsload = true;
      this.setState({ cookieBanner: this.context.GetCookie() });
      if (this.context.prodsAll.length > 0) {
        this.setState({ loadSpinnerOpen: false }); // showing the app
        this.context.SetHomePageLoadedStatus();
        return;
      }
      this.fakeRequest(100).then(() => {
        if (
          this.context.storeSettings != "" &&
          this.context.prodsAll.length == 0
        ) {
          this.fakeRequest(
            this.context.storeSettings.homePageLoadingTimeInSecond
          ).then(() => {
            this.context.SetHomePageLoadedStatus();
            this.setState({ loadSpinnerOpen: false }); // showing the app
          });
        } else {
          this.context.SetHomePageLoadedStatus();
          this.setState({ loadSpinnerOpen: false }); // showing the app
        }
      });
      this.fakeRequest(2000).then(() => {
        this.LoadEnterprise();
        this.setState({ loadSpinnerOpen: false }); // showing the app
      });
    }
  }

  componentDidUpdate = () => {
    if (this.context.refreshIncDecControl == true) {
      this.context.SetRefreshIncDecControl(false);
      this.setState({ reload: true }, () => {
        this.setState({ reload: false });
      });
    }
    // this.executeScroll();
  };

  render() {
    if (
      (this.context.storeId == "" ||
        this.context.mainCategories.length == 0 ||
        this.context.subCategories.length == 0) &&
      this.context.store.storeTypeId != this.context.storeType.Enterprise
    ) {
      return <></>;
    }

    return (
      <>
        <Helmet>
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet>

        {this.context.storeSettings.multiCurrencySupport == true &&
        this.context.islanguageSelected == false ? (
          <>
            <LanguageSelection></LanguageSelection>
          </>
        ) : (
          <></>
        )}

        {this.state.showEnterpriseStoreModel == false &&
        this.context.store.storeTypeId == this.context.storeType.Enterprise ? (
          <>
            <Button
              className="shop-now CornersRounded border-white"
              style={{
                fontSize: "64px",
                backgroundColor: "yellowgreen",
              }}
              onClick={(e) => this.ShopNow(e)}
            >
              &nbsp;&nbsp;Shop Now...&nbsp;&nbsp;
            </Button>
          </>
        ) : (
          <></>
        )}

        <div
          className="title-dsn"
          style={{
            backgroundColor: this.context.theme.homepageBackgroundColor,
            fontFamily: "Raleway ",
          }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {/* {this.state.loadSpinnerOpen == true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )} */}

          <MySEO></MySEO>

          {this.context.storeSettings.bannerVideoFileName ? (
            <HomeBannerVideo></HomeBannerVideo>
          ) : (
            ""
          )}

          {this.context.storeSettings.hideDisplayCounters === false &&
          this.context.storeSettings.bannerVideoFileName ? (
            <>
              <HomeCountersDisplay></HomeCountersDisplay>
              <br />
            </>
          ) : (
            <></>
          )}

          {this.context.theme.hideCarousel == false ? (
            <>
              <ConsumerMainCarousel></ConsumerMainCarousel>
            </>
          ) : (
            ""
          )}

          {this.context.storeSettings.hideDisplayCounters === false &&
          (this.context.storeSettings.bannerVideoFileName == undefined ||
            this.context.storeSettings.bannerVideoFileName == "") ? (
            <>
              <HomeCountersDisplay></HomeCountersDisplay>
              <br />
            </>
          ) : (
            <></>
          )}

          {this.context.theme.showAllProductsByMainCategoryOnHomePage ==
          true ? (
            <>
              <ShowAllProductsOfMainCategory></ShowAllProductsOfMainCategory>
            </>
          ) : (
            <></>
          )}

          {this.state.showEnterpriseStoreModel == true ? (
            <>
              <EnterpriseStoreModel
                onHide={this.hideEnterpriseStoreModel}
                GetMyStoreDetails={this.GetMyStoreDetails}
              ></EnterpriseStoreModel>
            </>
          ) : (
            <>
              <div
                style={{
                  marginLeft: this.context.theme.homeMarginSpace + "cm",
                  marginRight: this.context.theme.homeMarginSpace + "cm",
                  backgroundColor: this.context.theme.homepageBackgroundColor,
                }}
              >
                <div
                  className="mx-5 px-5"
                  style={{ textAlign: "justify", fontFamily: "Helvetica" }}
                  dangerouslySetInnerHTML={{
                    __html: this.context.storeSettings.homePageBizSummary,
                  }}
                />

                {this.context.store.businessTypeId == 20 ? (
                  <>
                    <ShowBrands></ShowBrands>
                  </>
                ) : (
                  <></>
                )}

                {/* Main Category */}
                <ShowMainCategory></ShowMainCategory>

                {/* ADs */}
                <Top5Ads></Top5Ads>

                {/* All Sub Category */}
                <div id="subcategory" ref={(ref) => (this.myRef = ref)}>
                  <ShowSubCategory></ShowSubCategory>
                </div>
                {/* Product Group Carousels */}
                <ShowProductGroups
                  reload={this.state.reload}
                ></ShowProductGroups>

                {this.context.store.businessTypeId == 20 ? (
                  <></>
                ) : (
                  <>
                    <ShowBrands></ShowBrands>
                  </>
                )}

                {this.context.storeSettings.hideTestimonials === false ? (
                  <>
                    <Testimonial></Testimonial>
                  </>
                ) : (
                  <></>
                )}

                {this.context.storeSettings.hideCertificates === false ? (
                  <>
                    <CertificatesSlider></CertificatesSlider>
                  </>
                ) : (
                  <></>
                )}
                {this.context.storeSettings.hideClientLogos === false ? (
                  <>
                    <ClientsSlider></ClientsSlider>
                  </>
                ) : (
                  <></>
                )}
                {this.context.storeSettings.hideVlogs === false ? (
                  <>
                    <Vlogs></Vlogs>
                  </>
                ) : (
                  <></>
                )}
                {this.context.storeSettings.hideBlogs === false ? (
                  <>
                    <Blogs></Blogs>
                  </>
                ) : (
                  <></>
                )}
                {/* <GoopleMap></GoopleMap> */}
                {/* <SimpleMap></SimpleMap> */}
              </div>
            </>
          )}
        </div>
        {window.location.hostname == "maicabagfactory.com" ||
        window.location.hostname == "www.maicabagfactory.com" ? (
          <Maicabagfactory></Maicabagfactory>
        ) : (
          <></>
        )}
        {/* {this.state.cookieBanner == false ? (
          <>
            <CookieBanner
              OnCloseCookieBanner={this.OnCloseCookieBanner}
            ></CookieBanner>
          </>
        ) : (
          <></>
        )} */}
      </>
    );
  }
}
export class Maicabagfactory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {/* <div className=" my-5 p-5" style={{ backgroundColor: "#00ff00" }}>
          <h1
            className="  mb-5 "
            style={{ textAlign: "center", color: "white" }}
          >
            Our Credentials
          </h1>
          <div className="row ">
            <div className="col-md-6 col-xl-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="text-center border p-5">
                <h1 className="display-6  " style={{ color: "white" }}>
                  Corporate Identity Number
                </h1>
                <span className="crpNum fw-bolder" style={{ color: "#02245b" }}>
                  XXXXXXXXXXXXXXXX
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 wow fadeIn">
              <div className="text-center border p-5">
                <h1 className="display-6  " style={{ color: "white" }}>
                  GST Number
                </h1>
                <span className="fs-5 fw-bolder" style={{ color: "#02245b" }}>
                  XXXXXXXXXXXXXXXXXXX
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 wow fadeIn">
              <div className="text-center border p-5">
                <h2 className="display-6  " style={{ color: "white" }}>
                  Importer - Exporter Code
                </h2>
                <span className="fs-5 fw-bolder" style={{ color: "#02245b" }}>
                  XXXXXXXXXXXXXXXXXXX
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <div class="row p-5 align-items-center">
          <h1 class="mb-0 py-5">
            <b>About us</b>
          </h1>
          <div
            class="col-lg-12 wow fadeIn"
            data-wow-delay="0.5s"
            style={{ fontFamily: "Raleway" }}
          >
            <h3>
              <b>The Heart of Craftsmanship: The Story of Maicabagfactory</b>
            </h3>
            <p>
              In the bustling world of fashion and functionality,
              Maicabagfactory stands as a beacon of quality and innovation. Our
              journey began with a simple idea: to create bags that blend style
              with durability, serving the needs of everyday life while making a
              statement. Our Origins
            </p>
            <p>
              Founded in 2017, Maicabagfactory was born from a passion for
              craftsmanship and a commitment to sustainability. Our founder, MD
              Abrar, envisioned a place where creativity could thrive, and every
              bag produced would tell a story. With a small team of skilled
              artisans, we set up our first workshop, equipped with the finest
              materials and a drive to make every product unique. The
            </p>
            <h4>
              <b>Crafting Process</b>
            </h4>
            <p>
              At Maicabagfactory, every bag is a labor of love. We believe in
              the beauty of handmade products, where each stitch and seam is
              carefully crafted. Our artisans combine traditional techniques
              with modern designs, ensuring that every bag is not just an
              accessory, but a piece of art.
            </p>
            <ol>
              <li>
                {" "}
                <b>Design:</b> Our creative team sketches designs inspired by
                the latest trends while considering the practical needs of our
                customers. Each concept goes through rigorous testing to ensure
                functionality and style.
              </li>{" "}
              <li>
                <b>Sourcing Materials:</b> We prioritize eco-friendly materials,
                sourcing premium fabrics that are not only stylish but also
                sustainable. Our commitment to the environment means we use
                recycled materials whenever possible.
              </li>{" "}
              <li>
                <b>Production:</b> With skilled hands and an eye for detail, our
                craftsmen meticulously construct each bag. From cutting to
                sewing, every step is executed with precision, ensuring quality
                that stands the test of time.
              </li>{" "}
              <li>
                <b>Quality Assurance:</b> Before a bag leaves our factory, it
                undergoes a thorough quality check. We believe that our
                customers deserve nothing less than perfection.
              </li>{" "}
            </ol>
            <p>
              <h4>
                <b>Our Commitment to Community</b>
              </h4>{" "}
              Maicabagfactory is more than just a manufacturing facility; we are
              a part of the community. We employ local artisans and provide them
              with fair wages and opportunities for skill development. Our goal
              is to empower individuals while fostering a supportive work
              environment where creativity can flourish.
            </p>
            <p>
              <h4>
                <b>Sustainability at Our Core</b>
              </h4>{" "}
              We understand the importance of preserving our planet for future
              generations. That’s why we actively seek to minimize waste through
              efficient production processes and a commitment to recycling. Our
              bags are designed to last, reducing the need for disposable
              fashion and encouraging a more sustainable lifestyle.
            </p>{" "}
            <p>
              <h4>
                <b>The Future of Maicabagfactory</b>
              </h4>{" "}
              As we look to the future, Maicabagfactory is excited to expand our
              collection and reach a broader audience. Our passion for quality
              and sustainability will continue to drive our innovation as we
              explore new designs, materials, and technologies. Join us on this
              journey as we create bags that not only elevate your style but
              also contribute to a better world. At Maicabagfactory, every bag
              tells a story—let yours begin today.
            </p>
          </div>
        </div>
        <div class="my-5 p-5 " style={{ backgroundColor: "#281C6C" }}>
          <h1
            class=" text-warning mb-5 animated "
            style={{ textAlign: "center" }}
          >
            Our Values
          </h1>
          <div class="row g-5">
            <div className="col-md-6 col-xl-3 ">
              <div className="text-center p-5 " style={{ height: "100%;" }}>
                <i class="fa fa-certificate fa-3x text-white mb-3"></i>
                <h1 class="display-6 text-warning valHead ">Quality</h1>
                <span class="fs-5 fw-semi-bold text-white">
                  We uphold strict standards to ensure our products meet and
                  exceed your expectations.
                </span>
              </div>
            </div>
            <div class="col-md-6 col-xl-3 ">
              <div
                class="text-center border-2 border-secondary p-5"
                style={{ height: "100%;" }}
              >
                {/* <i class="fas fa-users-cog fa-3x text-white mb-3"></i> */}
                <FaUserCog size={46} className="text-white mb-3" />
                <h1 class="display-6 text-warning valHead ">Efficiency</h1>
                <span class="fs-5 fw-semi-bold text-white">
                  Our optimized processes minimize lead time, getting your
                  products to you as quickly as possible.
                </span>
              </div>
            </div>
            <div class="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.5s">
              <div
                class="text-center border-1 border-secondary p-5"
                style={{ height: "100%;" }}
              >
                <i class="fa fa-eye fa-3x text-white mb-3"></i>
                <h2 class="display-6 text-warning valHead ">Transparency</h2>
                <span class="fs-5 fw-semi-bold text-white">
                  Track every step of your order's journey, from initial
                  sourcing to delivery.
                </span>
              </div>
            </div>
            <div class="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.7s">
              <div
                class="text-center border-1 border-secondary p-5"
                style={{ height: "100%;" }}
              >
                <i class="fa fa-users fa-3x text-white mb-3"></i>
                <h2 class="display-6 text-warning valHead  ">Partnership</h2>
                <span class="fs-5 fw-semi-bold text-white">
                  We view our relationship with you as a partnership, working
                  together to achieve your procurement goals.
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class EnterpriseStoreModel extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      storeId: "",
      pinCode: "",
      locationId: "",
    };
  }

  OnChangePincode = (event) => {
    this.setState({ pinCode: event.target.value });
  };
  componentDidMount = () => {
    var ctrl = document.getElementById("pincode");
    if (ctrl) ctrl.focus();
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    this.props.GetMyStoreDetails(this.state.pinCode);
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          // size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          // onHide={this.props.onHide}
          dialogClassName="ProductModal"
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-white text-dark">
              <h3>Select Delivery Location</h3>
            </Modal.Header>

            <Modal.Body
              className=""
              style={{
                fontSize: "16px",
              }}
            >
              <Row className="verticalaligncentertd">
                {/* <Col xs={6}>Enter Pincode:</Col>
                <Col xs={6}>
                  <Form.Control
                    id="pincode"
                    type="number"
                    placeholder=""
                    className=" "
                    required
                    minLength={6}
                    maxLength={6}
                    value={this.state.pinCode}
                    onChange={(e) => this.OnChangePincode(e)}
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "20px",
                    }}
                  />
                
                </Col> */}
                <Col>
                  <Form.Select
                    onChange={(e) => this.OnChangePincode(e)}
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "20px",
                    }}
                  >
                    <option value=""></option>
                    {this.context.store.enterpriseStores.map((m) => (
                      <option value={m.pincodes}>{m.location}</option>
                    ))}
                    <option value="">Other Location</option>
                  </Form.Select>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-green"
                // variant="primary"
                type="submit"
                size="lg"
                onClick={(e) => this.handleSubmit(e)}
              >
                <b>&nbsp;&nbsp;Proceed...&nbsp;&nbsp;</b>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export class ShowMainCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  onMainCategoryClickEvent = (event) => {
    this.context.GetProductsByMainCategoryId(event.currentTarget.id);
  };
  render() {
    const settings = {
      lazyLoad: true,
      arrows: true,
      infinite: false,
      speed: 1000,
      autoplay: false,
      slidesToShow: Math.round(
        window.innerWidth / (this.context.theme.mainCategorySlideWidth * 37),
        0
      ),
      slidesToScroll: 3,
      focusOnSelect: true,
      rows: this.context.theme.mainCategoryTotalRows
        ? this.context.theme.mainCategoryTotalRows
        : 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent",
    };
    return (
      <>
        {this.context.theme.hideMainCategory == false &&
        this.context.mainCategories.length > 0 ? (
          <>
            <div
              className=" px-2 py-1 my-5 title-dsn border-0 CornersRounded"
              style={{
                backgroundColor: this.context.theme.mainCategoryBackgroundColor
                  ? this.context.theme.mainCategoryBackgroundColor
                  : this.context.theme.homepageBackgroundColor,
              }}
            >
              <h3
                className={"" + this.context.theme.homepageTitlesAlign}
                style={{
                  fontSize: this.context.theme.homepageTitlesFontSize + "px",
                  color: this.context.theme.mainCategoryTextColor
                    ? this.context.theme.mainCategoryTextColor
                    : this.context.theme.homepageTitlesTextColor,
                  textTransform: this.context.theme.homepageTitlesTextCase,
                }}
              >
                <h1 class="bold_font py-3">
                  {this.context.theme.mainCategoryName}
                </h1>
                <span
                  style={{
                    textTransform: "lowercase",
                    textAlign: "",
                  }}
                >
                  {this.context.theme.hideProductCount == "false" ? (
                    <>- [{this.context.prodsAll.length} Products]</>
                  ) : (
                    ""
                  )}
                </span>
              </h3>

              <div className="border-0 py-0 m-0 ">
                <Slider {...settings}>
                  {this.context.mainCategories
                    .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                    .map((maincategory) => (
                      <>
                        <div className="border-0 border-dark py-3">
                          <div
                            className="card p-0 mb-1 mx-2 card-round border-0 "
                            style={{
                              backgroundColor: this.context.theme
                                .mainCategoryBackgroundColor
                                ? this.context.theme.mainCategoryBackgroundColor
                                : this.context.theme.homepageBackgroundColor,
                            }}
                          >
                            <div className="text-center imgcenter  img-hover-mover">
                              <Nav.Link
                                as={Link}
                                id={maincategory.id}
                                className=""
                                to="/ProductsList"
                                onClick={(e) => {
                                  this.onMainCategoryClickEvent(e);
                                }}
                                style={{
                                  color: this.context.theme.categoryTextColor,
                                  backgroundColor: this.context.theme
                                    .mainCategoryBackgroundColor
                                    ? this.context.theme
                                        .mainCategoryBackgroundColor
                                    : this.context.theme
                                        .homepageBackgroundColor,
                                }}
                              >
                                <img
                                  src={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    maincategory.imgFileName +
                                    this.context.store.storageSasToken
                                  }
                                  className={
                                    this.context.theme.mainCategoryDisplayShape
                                  }
                                  alt="..."
                                  style={{
                                    cursor: "pointer",

                                    width:
                                      this.context.theme
                                        .maincategoryImageWidth + "cm",
                                    height:
                                      this.context.theme
                                        .mainCategoryImageHeight + "cm",
                                  }}
                                ></img>
                                <h5
                                  className="text-center align-middle my-2"
                                  style={{
                                    height:
                                      this.context.theme
                                        .maincategoryNameHeight + "cm",
                                    color:
                                      this.context.theme.mainCategoryTextColor,
                                  }}
                                >
                                  <b>{maincategory.name}</b>{" "}
                                </h5>
                                <p
                                  style={{
                                    fontSize: "15px",
                                    textAlign: "justify",
                                    fontFamily: "Raleway",
                                  }}
                                >
                                  {maincategory.description}
                                </p>
                                <h6></h6>
                              </Nav.Link>
                              <span
                                class="notify-maincategory-badge-right_share"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share me"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                }}
                              >
                                <RWebShare
                                  data={{
                                    text: maincategory.name,
                                    url:
                                      window.location.origin +
                                      "/MainCategoryArg?name=" +
                                      maincategory.name.replace(/\s/g, "%20"),
                                    title: maincategory.name,
                                  }}
                                  onClick={() =>
                                    console.log("shared successfully!")
                                  }
                                >
                                  <FaShareAlt size="16" />
                                </RWebShare>
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </Slider>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export class ShowAllProductsOfMainCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="row justify-content-around m-0 py-2 ">
          {this.context.mainCategories
            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
            .map((maincategory, enventkey) => (
              <>
                <div>
                  <Accordion defaultActiveKey={[enventkey]} alwaysOpen>
                    <Accordion.Item
                      className="m-0 p-0"
                      eventKey={enventkey}
                      style={{
                        // color: "yellow",
                        backgroundColor:
                          this.context.theme.homepageBackgroundColor,
                      }}
                    >
                      <Accordion.Header>
                        <h1
                          className="text-center border-0 py-3"
                          style={{
                            width: "100%",
                            //color: this.context.theme.productCardTextColor,
                            // color: "yellow",
                            // backgroundColor:
                            //   this.context.theme.mainNavBarBackgroundColor,
                            fontFamily: "Helvetica",
                            fontSize: "24px",
                          }}
                        >
                          <b>{maincategory.name}</b>
                        </h1>
                      </Accordion.Header>
                      <Accordion.Body
                        className="m-0 p-0 row justify-content-around"
                        style={{
                          backgroundColor:
                            this.context.theme.homepageBackgroundColor,
                          // color: "yellow",
                          // backgroundColor: "darkolivegreen",
                        }}
                      >
                        {this.context.prodsAll
                          .filter((f) => f.mainCategoryId == maincategory.id)
                          .map((prod) => (
                            <>
                              <div
                                className="card mb-3 m-0 p-0 CornersRounded"
                                style={{
                                  // width: "100%",
                                  width: window.innerWidth / 5,
                                  // backgroundColor:
                                  //   this.context.theme.homepageBackgroundColor,
                                  borderColor:
                                    this.context.theme.homepageBackgroundColor,
                                }}
                              >
                                <ProductCard
                                  prod={prod}
                                  displayType="list"
                                ></ProductCard>
                              </div>
                            </>
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </>
            ))}
        </div>
      </>
    );
  }
}

export class ShowSubCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  onSubCategoryClickEvent = (event) => {
    this.context.GetProductsBySubCategoryId(event.currentTarget.id, null, null);
  };
  render() {
    const settingsAllCategory = {
      lazyLoad: true,
      arrows: true,
      speed: 500,
      infinite: false,
      // infinite:
      //   this.context.subCategories.length >
      //   Math.round(
      //     window.innerWidth /
      //       (this.context.theme.subCategorySlidesWidth == undefined
      //         ? 5 * 37
      //         : this.context.theme.subCategorySlidesWidth * 37),
      //     0
      //   ),
      autoplay: false,
      slidesToShow: Math.round(
        window.innerWidth /
          (this.context.theme.subCategorySlidesWidth == undefined
            ? 5 * 37
            : this.context.theme.subCategorySlidesWidth * 37),
        0
      ),
      slidesToScroll: 3,
      // dots: true,
      //slidesToShow: 6,
      rows: this.context.theme.subCategoryTotalRows
        ? this.context.theme.subCategoryTotalRows
        : 2,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent",
    };
    return (
      <>
        {this.context.theme.hideSubCategory == false &&
        this.context.subCategories.length > 0 ? (
          <>
            <div
              className="px-2 py-1 my-5 title-dsn border-0 CornersRounded"
              style={{
                backgroundColor: this.context.theme.subCategoryBackgroundColor
                  ? this.context.theme.subCategoryBackgroundColor
                  : this.context.theme.homepageBackgroundColor,
              }}
            >
              <h3
                className={
                  " producttitlefontandsizeDesktop " +
                  this.context.theme.homepageTitlesAlign
                }
                style={{
                  fontSize: this.context.theme.homepageTitlesFontSize + "px",
                  color: this.context.theme.subCategoryTextColor
                    ? this.context.theme.subCategoryTextColor
                    : this.context.theme.homepageTitlesTextColor,
                  textTransform: this.context.theme.homepageTitlesTextCase,
                }}
              >
                <h1
                  class="bold_font py-3"

                  // style={{ fontFamily: "Poppins" }}
                >
                  <b>{this.context.theme.subCategoryName}</b>
                </h1>
                <span
                  style={{
                    textTransform: "lowercase",
                    // fontSize: "16px"
                  }}
                >
                  {this.context.theme.hideProductCount == "false" ? (
                    <>- [{this.context.prodsAll.length} Products]</>
                  ) : (
                    ""
                  )}
                </span>
              </h3>
              <div className="border-0 py-0">
                <Slider {...settingsAllCategory}>
                  {this.context.subCategories
                    .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                    .map((subcategory) => (
                      <>
                        <div className="border-0 border-dark text-center py-3">
                          <div
                            className="card p-0  mx-2 mb-3 border-0 border border-dark  text-center"
                            style={{
                              // width:
                              //   this.context.theme.subCategoryCardWidth +
                              //   "cm",
                              backgroundColor: this.context.theme
                                .subCategoryBackgroundColor
                                ? this.context.theme.subCategoryBackgroundColor
                                : this.context.theme.homepageBackgroundColor,
                              // this.context.theme.homepageBackgroundColor,
                            }}
                          >
                            <div className="text-center imgcenter img-hover-mover">
                              <Nav.Link
                                as={Link}
                                id={subcategory.id}
                                // className=" navBarLink text-center align-center"
                                to="/ProductsList"
                                onClick={(e) => {
                                  this.onSubCategoryClickEvent(e);
                                }}
                                style={{
                                  color: this.context.theme.subCategoryTextColor
                                    ? this.context.theme.subCategoryTextColor
                                    : this.context.theme
                                        .homepageTitlesTextColor,
                                  backgroundColor: this.context.theme
                                    .subCategoryBackgroundColor
                                    ? this.context.theme
                                        .subCategoryBackgroundColor
                                    : this.context.theme
                                        .homepageBackgroundColor,
                                  // this.context.theme.homepageBackgroundColor,
                                }}
                              >
                                <img
                                  src={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    subcategory.imgFileName +
                                    this.context.store.storageSasToken
                                  }
                                  className={
                                    // "btn3d-default-light " +
                                    this.context.theme
                                      .subCategoryAndBrandDisplayShape
                                  }
                                  alt="..."
                                  style={{
                                    cursor: "pointer",
                                    width:
                                      this.context.theme
                                        .subCategoryImageHeight + "cm",
                                    height:
                                      this.context.theme
                                        .subCategoryImageHeight + "cm",
                                  }}
                                ></img>
                                <h6
                                  className="text-center my-3"
                                  style={{
                                    height:
                                      this.context.theme.subcategoryNameHeight +
                                      "cm",
                                    color: this.context.theme
                                      .subCategoryTextColor
                                      ? this.context.theme.subCategoryTextColor
                                      : this.context.theme
                                          .homepageTitlesTextColor,
                                  }}
                                >
                                  <b>{subcategory.name}</b>{" "}
                                </h6>
                              </Nav.Link>
                              <span
                                class="notify-subcategory-badge-right_share"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share me"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                }}
                              >
                                <RWebShare
                                  data={{
                                    text: subcategory.name,
                                    url:
                                      window.location.origin +
                                      "/SubCategoryArg?name=" +
                                      subcategory.name.replace(/\s/g, "%20"),
                                    title: subcategory.name,
                                  }}
                                  onClick={() =>
                                    console.log("shared successfully!")
                                  }
                                >
                                  <FaShareAlt size="16" />
                                </RWebShare>
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </Slider>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
export class ShowProductGroups extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  onViewAllProductGroupClickEvent = (event) => {
    this.context.GetProductsByProductgroupId(event.currentTarget.id);
  };

  render() {
    const settingsProductGroup = {
      lazyLoad: true,
      arrows: true,
      //centerMode: true,
      //infinite: true,
      //className: "center",

      slidesToScroll: 3,
      // slidesToShow:
      //   this.context.theme.noOfCardsOnProductGroupToShow == undefined
      //     ? "5"
      //     : this.context.theme.noOfCardsOnProductGroupToShow,
      // slidesToShow: Math.round(
      //   window.innerWidth /
      //     (this.context.theme.productGropupSlidesWidth == undefined
      //       ? 6 * 37
      //       : this.context.theme.productGropupSlidesWidth * 37),
      //   0
      // ),
      slidesToShow: this.context.theme.slidesToShowInProductList
        ? this.context.theme.slidesToShowInProductList + 1
        : 5,
      //slidesToShow: 4,
      // dots: true,
      // autoplay: true,

      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent",
    };
    return (
      <>
        <Row className="my-0">
          {this.context.productGroups
            .filter((f) => f.isCarouselType == true)
            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
            .map((pg) => (
              <>
                <Col>
                  <ProductGroupCarousel
                    productGroup={pg}
                  ></ProductGroupCarousel>
                </Col>
              </>
            ))}
        </Row>

        {this.props.reload == false ? (
          <>
            {this.context.prodsAll.length > 0
              ? this.context.productGroups
                  .filter((f) => f.isCarouselType != true)
                  .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                  .map((pg) =>
                    pg.productGroupAndProductsMaps.length > 0 ? (
                      <>
                        <div
                          className="px-2 py-3 my-2  border-0 TopCornerRounded"
                          style={{
                            backgroundColor:
                              this.context.theme.productGroupBackgroundColor,
                          }}
                        >
                          <Row className="py-2">
                            <Col md={1}></Col>
                            <Col md={10}>
                              <div
                                className={
                                  "producttitlefontandsizeDesktop " +
                                  this.context.theme.homepageTitlesAlign
                                }
                                style={{
                                  fontSize:
                                    this.context.theme.homepageTitlesFontSize +
                                    "px",
                                  color:
                                    this.context.theme.homepageTitlesTextColor,
                                  textTransform:
                                    this.context.theme.homepageTitlesTextCase,
                                }}
                              >
                                <h1 class="bold_font">
                                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                  {pg.name}{" "}
                                </h1>
                              </div>
                            </Col>
                            <Col md={1} className="text-end">
                              <h5>
                                <Button
                                  as={Link}
                                  id={pg.id}
                                  to="/ProductsList"
                                  className=" text-end p-2 border-0"
                                  style={{
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,

                                    textTransform: "none",
                                  }}
                                  onClick={(e) => {
                                    this.onViewAllProductGroupClickEvent(e);
                                  }}
                                >
                                  <b>View All</b>
                                </Button>
                              </h5>
                            </Col>
                          </Row>
                          <br />
                          <div
                            className=""
                            id="productlistdiv"
                            style={{
                              backgroundColor:
                                this.context.theme.homepageBackgroundColor,
                            }}
                          >
                            <Slider
                              {...settingsProductGroup}
                              infinite={false}
                              centerMode={false}
                              backgroundColor={
                                this.context.theme.homepageBackgroundColor
                              }
                              style={{
                                backgroundColor:
                                  this.context.theme.homepageBackgroundColor,
                              }}
                            >
                              {pg.productGroupAndProductsMaps != undefined
                                ? pg.productGroupAndProductsMaps
                                    .sort((a, b) =>
                                      a.seqNo > b.seqNo ? 1 : -1
                                    )
                                    .map((map) =>
                                      this.context.prodsAll.find(
                                        (f) => f.id == map.productId
                                      ) != undefined ? (
                                        <div
                                          className="text-center mx-0 my-2 "
                                          id=""
                                          style={{
                                            backgroundColor:
                                              this.context.theme
                                                .homepageBackgroundColor,
                                          }}
                                        >
                                          <div
                                            className="card p-0 my-0  text-center CornersRounded"
                                            style={{
                                              width: "98%",
                                              // width:
                                              //   this.context.theme
                                              //     .productCardWidth + "cm",
                                              backgroundColor:
                                                this.context.theme
                                                  .homepageBackgroundColor,
                                            }}
                                          >
                                            <div
                                              className=" text-center m-0 p-0 pb-0 CornersRounded border-0 border-dark"
                                              style={{
                                                // backgroundColor:
                                                //   this.context.theme
                                                //     .productCardBackgroundColor,
                                                backgroundColor:
                                                  this.context.theme
                                                    .homepageBackgroundColor,
                                              }}
                                            >
                                              <ProductCard
                                                prod={this.context.prodsAll.find(
                                                  (f) => f.id == map.productId
                                                )}
                                              ></ProductCard>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    )
                                : ""}
                            </Slider>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  )
              : ""}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
export class ShowBrands extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBrandClickEvent = (event) => {
    this.context.GetProductsByBrandId(event.currentTarget.id);
  };

  render() {
    const settingsBrands = {
      lazyLoad: true,
      arrows: true,
      speed: 500,
      infinite: false,
      autoplay: false,
      slidesToShow: Math.round(
        window.innerWidth /
          (this.context.theme.subCategorySlidesWidth == undefined
            ? 5 * 37
            : this.context.theme.subCategorySlidesWidth * 37),
        0
      ),

      slidesToScroll: 3,
      rows: this.context.brands.length > 6 ? 2 : 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent",
    };
    return (
      <>
        {this.context.brands.length > 0 ? (
          <>
            <div className="px-2 py-3 m-2 title-dsn">
              <h3
                className={
                  "producttitlefontandsizeDesktop " +
                  this.context.theme.homepageTitlesAlign
                }
                style={{
                  fontSize: this.context.theme.homepageTitlesFontSize + "px",
                  color: this.context.theme.homepageTitlesTextColor,
                  textTransform: this.context.theme.homepageTitlesTextCase,
                }}
              >
                <h1 class="bold_font">
                  {/* Audio Store */}
                  {this.context.store.businessTypeId == 20
                    ? "Browse by Artist"
                    : "Shop by Brands"}
                </h1>
                {this.context.theme.hideProductCount == "false" ? (
                  <>
                    <span
                      style={{
                        textTransform: "lowercase",
                      }}
                    >
                      - [
                      {
                        this.context.prodsAll.filter((f) => f.brandId != null)
                          .length
                      }{" "}
                      Products]
                    </span>
                  </>
                ) : (
                  ""
                )}
              </h3>
              <div className="border-0 py-3">
                <Slider {...settingsBrands}>
                  {this.context.brands.map((brand) => (
                    <>
                      <div className="border-0 border-dark text-center py-3">
                        <div
                          className="card p-0  mx-2 mb-3 border-0 border border-dark  text-center"
                          style={{
                            backgroundColor:
                              this.context.theme.homepageBackgroundColor,
                          }}
                        >
                          <div className="text-center imgcenter img-hover-mover">
                            <Nav.Link
                              as={Link}
                              id={brand.id}
                              to="/ProductsList"
                              onClick={(e) => {
                                this.onBrandClickEvent(e);
                              }}
                              style={{
                                color: this.context.theme.categoryTextColor,
                              }}
                            >
                              <img
                                src={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  brand.imgFileName +
                                  this.context.store.storageSasToken
                                }
                                className={
                                  this.context.theme
                                    .subCategoryAndBrandDisplayShape
                                }
                                alt="..."
                                style={{
                                  cursor: "pointer",
                                  width:
                                    this.context.theme.subCategoryImageHeight ==
                                    undefined
                                      ? "0cm"
                                      : this.context.theme
                                          .subCategoryImageHeight + "cm",
                                  height:
                                    this.context.theme.subCategoryImageHeight ==
                                    undefined
                                      ? "0cm"
                                      : this.context.theme
                                          .subCategoryImageHeight + "cm",
                                }}
                              ></img>
                              <h6 className="text-center my-3">
                                <b>{brand.brandName}</b>{" "}
                              </h6>
                            </Nav.Link>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </Slider>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export class Top5Ads extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  OnAdClick = (e, ad) => {
    this.context.UpdateReloadProductListView("true");
    if (ad.adType == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(ad.mainCategoryId);
    } else if (ad.adType == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(ad.subCategoryId, null, null);
    } else if (ad.adType == 3) {
      this.context.GetProductsByProductgroupId(ad.productGroupId);
    }
  };
  GetRedirectPathForProductMenu(menu) {
    if (menu.productGroupId != null) return "/ProductListViewByProductGroup";
    if (menu.mainCategoryId != null) return "/ProductListViewByMainCategory";
    if (menu.subCategoryId != null) return "/ProductListViewBySubCategory";
  }

  render() {
    return (
      <>
        <div>
          {this.context.top5Ads.length == 1 ? (
            <>
              <Row className="m-0 p-0  border-0 text-center align-middle w-100">
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[0]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[0].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className=""
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          // height: "100%",
                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[0]);
                        }}
                      ></img>
                      {/* <button class="btn" className="">
                          Shop Now
                        </button> */}
                    </div>
                  </Nav.Link>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          {this.context.top5Ads.length == 2 ? (
            <>
              <Row className="m-0 p-0  border-0 text-center align-middle w-100">
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[0]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[0].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className=""
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",

                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[0]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[1]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[1].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className=""
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",

                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[1]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          {this.context.top5Ads.length == 3 ? (
            <>
              <Row className="m-0 p-0  border-0 text-center align-middle w-100">
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[0]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[0].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className="TopCornerRounded"
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[0]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[1]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[1].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className="TopCornerRounded"
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",

                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[1]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[2]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[2].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className="TopCornerRounded"
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",

                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[2]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          {this.context.top5Ads.length == 4 ? (
            <>
              <Row className="m-0 p-0  border-0 text-center align-middle w-100">
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[0]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[0].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className="TopCornerRounded"
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[0]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[1]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[1].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className="TopCornerRounded"
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",

                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[1]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
              </Row>
              <Row className="m-0 p-0  border-0 text-center align-middle w-100">
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[2]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[2].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className="TopCornerRounded"
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",

                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[2]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
                <Col className=" border-0">
                  <Nav.Link
                    as={Link}
                    to={this.GetRedirectPathForProductMenu(
                      this.context.top5Ads[3]
                    )}
                    exact
                  >
                    <div className="img-hover-mover m-0 p-0 w-100">
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.top5Ads[3].imgFileName +
                          this.context.store.storageSasToken
                        }
                        className="TopCornerRounded"
                        alt="..."
                        style={{
                          cursor: "pointer",
                          width: "100%",

                          objectFit: "contain",
                        }}
                        onClick={(e) => {
                          this.OnAdClick(e, this.context.top5Ads[3]);
                        }}
                      ></img>
                    </div>
                  </Nav.Link>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          {this.context.top5Ads.length == 5 ? (
            <>
              <Table
                className=" text-center my-5 p-0"
                height="200px"
                style={{
                  // tableLayout: "fixed",
                  verticalAlign: "top",
                }}
              >
                <tbody className=" m-0 p-0  " style={{ verticalAlign: "top" }}>
                  <tr className="m-0 p-0  border-0 text-center align-middle">
                    <td
                      className=" border-0"
                      width="30%"
                      height={window.innerHeight / 3 + "px"}
                    >
                      <Nav.Link
                        as={Link}
                        to={this.GetRedirectPathForProductMenu(
                          this.context.top5Ads[0]
                        )}
                        exact
                      >
                        <div className="img-hover-mover m-0 p-0 w-100">
                          <div className="image-container-for-button">
                            <img
                              src={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/images/" +
                                this.context.top5Ads[0].imgFileName +
                                this.context.store.storageSasToken
                              }
                              className=""
                              alt="..."
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              onClick={(e) => {
                                this.OnAdClick(e, this.context.top5Ads[0]);
                              }}
                            ></img>
                            <button class="btn1" className="">
                              Shop Now
                            </button>
                          </div>
                        </div>
                      </Nav.Link>
                    </td>
                    <td
                      className="border-0 "
                      rowSpan={2}
                      width="40%"
                      height={(window.innerHeight / 3) * 2 + "px"}
                    >
                      <Nav.Link
                        as={Link}
                        to={this.GetRedirectPathForProductMenu(
                          this.context.top5Ads[1]
                        )}
                        exact
                      >
                        <div className="img-hover-mover m-0 p-0 w-100">
                          <img
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.context.top5Ads[1].imgFileName +
                              this.context.store.storageSasToken
                            }
                            className={" "}
                            alt="..."
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            onClick={(e) => {
                              this.OnAdClick(e, this.context.top5Ads[1]);
                            }}
                          ></img>
                        </div>
                      </Nav.Link>
                    </td>
                    <td
                      className="border-0 "
                      width="30%"
                      height={window.innerHeight / 3 + "px"}
                    >
                      <Nav.Link
                        as={Link}
                        to={this.GetRedirectPathForProductMenu(
                          this.context.top5Ads[2]
                        )}
                        exact
                      >
                        <div className="img-hover-mover m-0 p-0 w-100">
                          <img
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.context.top5Ads[2].imgFileName +
                              this.context.store.storageSasToken
                            }
                            className={" "}
                            alt="..."
                            style={{
                              cursor: "pointer",

                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            onClick={(e) => {
                              this.OnAdClick(e, this.context.top5Ads[2]);
                            }}
                          ></img>
                        </div>
                      </Nav.Link>
                    </td>
                  </tr>
                  <tr className="m-0 p-0 border-0 ">
                    <td
                      className="border-0"
                      width="30%"
                      height={window.innerHeight / 3 + "px"}
                    >
                      <Nav.Link
                        as={Link}
                        to={this.GetRedirectPathForProductMenu(
                          this.context.top5Ads[3]
                        )}
                        exact
                      >
                        <div className="img-hover-mover m-0 p-0 w-100">
                          <img
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.context.top5Ads[3].imgFileName +
                              this.context.store.storageSasToken
                            }
                            className={""}
                            alt="..."
                            style={{
                              cursor: "pointer",

                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            onClick={(e) => {
                              this.OnAdClick(e, this.context.top5Ads[3]);
                            }}
                          ></img>
                        </div>
                      </Nav.Link>
                    </td>
                    <td
                      className="border-0"
                      width="30%"
                      height={window.innerHeight / 3 + "px"}
                    >
                      <Nav.Link
                        as={Link}
                        to={this.GetRedirectPathForProductMenu(
                          this.context.top5Ads[4]
                        )}
                        exact
                      >
                        <div className="img-hover-mover m-0 p-0 w-100">
                          <img
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.context.top5Ads[4].imgFileName +
                              this.context.store.storageSasToken
                            }
                            className={""}
                            alt="..."
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            onClick={(e) => {
                              this.OnAdClick(e, this.context.top5Ads[4]);
                            }}
                          ></img>
                        </div>
                      </Nav.Link>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

export class CookieBanner extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      acceptedCookies: false,
    };
  }

  acceptAllCookies = (e) => {
    this.context.SetCookie(true);
    this.props.OnCloseCookieBanner(true);
  };

  handleClose = () => {
    this.props.OnCloseCookieBanner(true);
  };

  render() {
    return (
      <Offcanvas show={true} onHide={this.handleClose} placement="bottom">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="cookie-banner text-center">
            <h1>Accept Cookies</h1>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="cookie-banner text-center">
            <p>
              This website uses cookies to ensure you get the best experience on
              our website.
            </p>
            <Button onClick={(e) => this.acceptAllCookies(e)}>
              Accept All Cookies
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
}
